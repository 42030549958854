import React, { useState } from 'react';
import { agent } from '../../../../api/agent';
import { dataHooks } from '../../../../hooks/dataHooks';
import { Button } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import PopConfirm from '@plasma/ui.feedback.pop-confirm';
import { useTranslation } from 'react-i18next';
import Secure from "@plasma/ui.application.secure"

interface Props {
  servicdeId: string;
}

const ServiceHistoryStyle = styled.div`
  .element-container {
    min-width: 100px;
    padding-left: 5px;
    width: 100%;
    &:first-child:not(:only-child){
      border-bottom: 1px solid lightgray;
    }

    &.not-in-use {
      color: #a6a6a6;
    }
    .delete-button {
      color: red;
    }
  }
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 10px;
`;

export default function ServiceHistory(props: Props) {
  const {t} = useTranslation()
  //@ts-ignore
  const adminRole = window.env.ADMIN_ROLE;
  const { data, refetch } = dataHooks.useGetInitialData<
    { version: string; title: string; inUse: boolean; id: string }[]
  >(() => agent.endpointGroup.getDocumentations(props.servicdeId), false, true);
  const [elementToDelete, setElementToDelete] = useState<string>();
  const { execute, loading: isDeleting } = dataHooks.useApiCall(null, t("notification.document.delete.success"), 'delete not success');

  const downloadHandler = (title: string, version: string, id: string) => {
    agent.endpointGroup.getDocumentById(id).then((response) => {
      const jsonString = JSON.stringify(response, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${title} (v.${version}).json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  if (data)
    return (
      <ServiceHistoryStyle>
        {data
          ?.sort((a, b) => (a.inUse === b.inUse ? 0 : a.inUse ? -1 : 1))
          .map((d) => (
            <div className={`element-container ${!d.inUse ? 'not-in-use' : ''}`}>
              <Button
                className="document-button"
                type="link"
                onClick={() => {
                  downloadHandler(d.title, d.version, d.id);
                }}
                icon={<DownloadOutlined />}
              ></Button>
              {d.version}
              {!d.inUse ? (
                   <Secure permissions={[adminRole]}>
                <PopConfirm
                  title={t("document.delete.confirm.message")}
                  placement="right"
                  onConfirm={() => {
                    setElementToDelete(d.id);
                    execute(() =>
                      agent.endpointGroup.deleteDocument(d.id).then(() => {
                        refetch().finally(() => {
                          setElementToDelete(undefined);
                        });
                      }),
                    );
                  }}
                >

                  <Button
                    loading={isDeleting && d.id === elementToDelete}
                    className="delete-button"
                    type="link"
                    icon={<DeleteOutlined />}
                  ></Button>
                </PopConfirm>
                </Secure>
              ) : null}
            </div>
          ))}
      </ServiceHistoryStyle>
    );
  return null;
}
