import React, { Suspense } from 'react';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/
import { Navigate, Route } from '@plasma/ui.application.router';
const ApiFromServiceCreator = React.lazy(() => import('.'));
//@ts-ignore
const adminRole = window.env.ADMIN_ROLE;
export const ApiFromServiceCreatorRoutes = [
  new Route({
    permissions:[adminRole],
    path: '/apis/create-from-services',
    element: ApiFromServiceCreator,
  }),
];
