import React, { useMemo, useRef, useState } from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import { ApiTestFormProps } from './ApiTestForm';
import { AuthenticationMethods } from '../../../../models/enums/AuthenticationMethods';
import { enumToOptions } from '../../../../utils/enumToOptions';
import { pathToParams } from '../../../../utils/pathToQueryParams';
import { translations } from '../../../../i18n';

export interface ApiTestProps {
  token: string;
  authMethod: string;
  customAuth: boolean;
  parameter: { query: string; parameter: string }[];
  requestBody: string;
}
function useApiTestFormHook(props: ApiTestFormProps) {
  useResourceBundle(translations, undefined, 'd0f9ee37-4373-4907-93cb-ed4cab88fdec');

  const { t } = useTranslation();
  const [authOn, setAuthOn] = useState(false);
  const valueRef = useRef<any>(props.apiProps);
  const authMethods = enumToOptions(AuthenticationMethods);

  const defaultProps = useMemo<ApiTestProps>(() => {
    return {
      token: '',
      authMethod: AuthenticationMethods.Bearer,
      customAuth: false,
      parameter: pathToParams(props.apiProps.path).queries,
      requestBody: props.apiProps.requestBody,
    };
  }, [props.apiProps]);

  return {
    t,
    authMethods,
    authOn,
    setAuthOn,
    defaultProps,
    valueRef,
  };
}

export default useApiTestFormHook;
