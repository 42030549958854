import React, { memo, ReactNode } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Button from '@plasma/ui.general.button';
import useResponseCreatorHook from './response-creator.hook';
import * as styled from './response-creator.styles';
import themes from './themes';
import TextInput from '@plasma/ui.input.text-input';
import { Icon } from '@plasma/ui.general.icon';
import Modal from '@plasma/ui.layout.modal';
import DescriptionModalContent from './BodyModalContent/BodyModalContent';
import Table, { ColumnsType, ColumnType } from 'antd/lib/table';
import 'overlayscrollbars/overlayscrollbars.css';
import ScrollContainer from '../../core/ScrollContainer/ScrollContainer';
import { response } from '../../../models/types/response';
import { v1 as uuidv1 } from 'uuid';

export interface ResponseCreatorProps {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  lock?: boolean;
  onChange?: (data: response[]) => void;
  value?: response[];
}

type Props = ResponseCreatorProps & ThemeOverride;

function ResponseCreator(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useResponseCreatorHook(props);
  const column: ColumnsType<any> = [
    {
      title: logic.t('header.code'),
      width:"80px",
      dataIndex: 'code',
      render: (name: string, record, index) => {
        return (
          <TextInput
            className={`codeField ${props.lock ? 'locked' : ''}`}
            onChange={(e) => {
              if (!props.lock) logic.changeHandler(index, 'code', e.target.value);
            }}
            placeholder="Code"
            value={name}
          />
        );
      },
    },
    {
      title: logic.t('header.description'),
      dataIndex: 'description',
      render: (name: string, record, index) => {
        return (
          <TextInput
            className={`descriptionField ${props.lock ? 'locked' : ''}`}
            onChange={(e) => {
              if (!props.lock) logic.changeHandler(index, 'description', e.target.value);
            }}
            placeholder="Description"
            value={name}
          />
        );
      },
    },
    {
      title: '',
      width: "100px",
      render: (text, record, index) => {
        return (
          <div className="action-bar">
            {' '}
            <Button
              disabled={props.lock && !(record.body.length > 0)}
              onClick={() => {
                logic.openDescrModal(index);
              }}
              type={(record.body.content?.length > 0 || record.body.type?.length > 0) ? 'primary' : 'secondary'}
              title="Body"
            />
            {!props.lock ? (
              <Button
                onClick={() => {
                  logic.removeHandler(index);
                }}
                type="text"
                icon={<Icon name="remove" />}
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.ResponseCreator className={`parameter-creator ${className ?? ''}`.trim()} style={style}>
        <div className="listContainer">
          <ScrollContainer  className="scrollContainer">
            <Table rowKey={uuidv1()} pagination={false} columns={column} dataSource={logic.dataSource} />
            {logic.isEmpty ? <div className="no-data-overlay" /> : null}
          </ScrollContainer>
        </div>
        {!props.lock ? <Button onClick={logic.addHandler} title="Add" className="addButton" /> : null}
        <Modal
          visible={logic.descrModal !== undefined ? true : false}
          title="Body"
          okText="Ok"
          cancelText="Cancel"
          onCancel={logic.closeDescrModal}
          onOk={logic.saveTempBody}
        >
          <DescriptionModalContent value={logic.tempBodyInfo} changeHandler={logic.tempDescrChangeHandler} />
        </Modal>
      </styled.ResponseCreator>
    </ThemeProvider>
  );
}

export default ResponseCreator
