import React from 'react';
import { Route } from '@plasma/ui.application.router';
import { ApiBuilderRoutes } from '../../../pages/api-management/api-builder';
import { ApiOverviewRoutes } from '../../../pages/api-management/api-overview';
import { ServiceOverviewRoutes } from '../../../pages/service-management/service-overview';
import { ServiceBuilderRoutes } from '../../../pages/service-management/service-builder';
import { ApiFromServiceCreatorRoutes } from '../../../pages/api-management/api-from-service-creator/api-from-service-creator.routes';
import { ApiExportRoutes } from '../../../pages/api-management/api-export';
import { ApiSwaggerRoutes } from '../../../pages/api-management/api-swagger-documentation';
import { ServiceSwaggerRoutes } from '../../../pages/service-management/service-swagger-documentation';

const Layout = React.lazy(() => import('.'));

export const LayoutRoutes = new Route({
  path: '',
  element: Layout,
  children: [
    ...ApiBuilderRoutes,
    ...ApiOverviewRoutes,
    ...ApiExportRoutes,
    ...ApiFromServiceCreatorRoutes,
    ...ApiSwaggerRoutes,
    ...ServiceOverviewRoutes,
    ...ServiceBuilderRoutes,
    ...ServiceSwaggerRoutes
  ],
});
