import { Help } from '@plasma/ui.application.app';

const help: Help[] = [
  // {
  //   title: 'EndpointInfo',
  //   description: 'description of EndpointInfo',
  //   url: '/endpoint-info',
  // },
];

export default help;
