import styled from 'styled-components';

export const ServiceForm = styled.div`
  width: 100%;
  height: 100%;
  .tableContainer {
    height: 100%;
    padding-top: 10px;
  }

  .cell-select {
    padding: 0px;
    width: 100%;
  }
`;
