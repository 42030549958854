import React, { useEffect, useReducer, useState } from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import translations from './i18n';

import { ParameterMapperProps, State } from './ParameterMapper';

function useParameterMapperHook(props: ParameterMapperProps) {
  useResourceBundle(translations, undefined, '49097f59-f669-4fb3-a234-3caa85a8a31e');

  const { t } = useTranslation();

  type Action =
    | {
        type: 'ADD_ITEM';
        payload: {
          query: string;
          parameter: string;
        };
      }
    | {
        type: 'REMOVE_ITEM';
        payload: number;
      }
    | {
        type: 'UPDATE_ITEM';
        payload: { index: number; value: string; key: 'query' | 'parameter' };
      };

  const paramsReducer = (state: State[], action: Action): State[] => {
    switch (action.type) {
      case 'ADD_ITEM':
        return [...state, action.payload];
      case 'REMOVE_ITEM':
        return state.filter((_, index) => index !== action.payload);
      case 'UPDATE_ITEM':
        const { index, value, key } = action.payload;
        return state.map((item, idx) => (idx === index ? { ...item, [key]: value ?? null } : item));
      default:
        return state;
    }
  };
  const [arrayState, dispatch] = useReducer(paramsReducer, props.value ?? []);

  const changeHandler = (index: number, value: string, key: 'query' | 'parameter') => {
    dispatch({ type: 'UPDATE_ITEM', payload: { index, value, key } });
  };

  const addHandler = () => {
    dispatch({
      type: 'ADD_ITEM',
      payload: { query: 'some query', parameter: 'some parameter' },
    });
  };

  const removeHandler = (i: number) => {
    dispatch({ type: 'REMOVE_ITEM', payload: i });
  };

  useEffect(() => {
    if (props.onChange) props.onChange(arrayState);
  }, [arrayState]);

  return {
    t,
    addHandler,
    changeHandler,
    arrayState,
    removeHandler,
  };
}

export default useParameterMapperHook;
