import React, { memo } from 'react';

export interface StatusIndicatorProps {
  active: boolean;
  size: number;
  falseVaulue?: string;
  trueVaulue?: string;
}

function StatusIndicator(props: StatusIndicatorProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          marginRight: '5px',
          borderRadius: '50%',
          width: props.size,
          float: 'left',
          height: props.size,
          backgroundColor: props.active ? 'green' : 'red',
        }}
      />
      <span>{props.active ? props.trueVaulue ?? 'Active' : props.falseVaulue ?? 'Inactive'}</span>
    </div>
  );
}

export default memo(StatusIndicator) as unknown as typeof StatusIndicator;
