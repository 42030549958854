import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import styled from 'styled-components';

interface Props {
  verticalMargin?: number;
  className?: string;
  autoHide?: boolean;
  children?: React.ReactNode;
  offsetHeight?: number;
  disableY?: boolean;
}

const ScrollContainerBase = styled(OverlayScrollbarsComponent)<{ offsetheight: number }>`

  .os-theme-dark {
    --os-handle-bg: #bcd5f1 !important;
    --os-handle-bg-hover: #95beec !important;
    --os-handle-bg-active: #95beec !important;
  }
  .os-scrollbar-vertical {
    height: ${(props) => `calc(100% - ${props.offsetheight}px)`};
    margin-top: ${(props) => `${props.offsetheight}px`};
  }
`;

export default function ScrollContainer(props: Props) {
  return (
    <ScrollContainerBase
      offsetheight={props.offsetHeight ?? 0}
      className={props.className}
      defer
      options={{
        overflow: {
          /** The overflow behavior of the vertical (y) axis. */
          y: props.disableY ? 'hidden' : 'scroll',
        },
        scrollbars: { autoHide: props.autoHide ? 'leave' : undefined, autoHideDelay: 0.2 },
      }}
    >
      {props.children}
    </ScrollContainerBase>
  );
}
