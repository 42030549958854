import { Help } from '@plasma/ui.application.app';

const help: Help[] = [
  // {
  //   title: 'ProcessorForm',
  //   description: 'description of ProcessorForm',
  //   url: '/ProcessorForm',
  // },
];

export default help;
