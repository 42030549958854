import React from 'react';
import ThemeProvider, { SizeType } from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useDetailsHook from './api-builder.hook';
import * as styled from './api-builder.styles';
import themes from './themes';
import Tabs from '@plasma/ui.navigation.tabs';
import Button from '@plasma/ui.general.button';
import Icon from '@plasma/ui.general.icon';
import { observer } from 'mobx-react-lite';
import Modal from '@plasma/ui.layout.modal';
import ApiCreateForm from '../../../components/features/api-builder/ApiCreateForm';
import { ApiMapping } from '../../../components/features/api-builder/ApiPipelineMapper';
import ApiTestForm from '../../../components/features/api-builder/ApiTestForm';
import SmsSpin from '../../../components/core/SmsSpin/SmsSpin';

export interface DetailsProps {
  type: 'create' | 'update';
  className?: string;
}

type Props = DetailsProps & ThemeOverride;

function Details(props: Props) {
  const { className, size, themeMode } = props;

  const logic = useDetailsHook(props);

  const handleModalConfirm = () => {
    Modal.confirm({
      title: logic.t('delete.confirm.title'),
      icon: <Icon style={{ color: 'red' }} name="GppMaybeOutlined" />,
      content: logic.t('delete.confirm.message'),
      sizeTheme: size,
      onOk() {
        logic.deleteHandler();
      },
      centered: true,
      okType: 'danger',
      visible: true,
    });
  };

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.Details className={`details ${className ?? ''}`.trim()}>
        <div className="tab-container">
          {logic.loading ? (
            <SmsSpin message='Loading...' />
          ) : (
            <Tabs type="card" defaultActiveKey="1">
              <Tabs.TabPane tab={logic.t('tab.basic')} key="1">
                <ApiCreateForm className="createForm" store={logic.ApiStore} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={logic.t('tab.mapping')} key="2">
                <ApiMapping store={logic.ApiStore} />
              </Tabs.TabPane>
            </Tabs>
          )}
        </div>
        <div className="button-container">
          <Button
            disabled={logic.isDeleting || !logic.enableSave}
            loading={logic.isSaveing}
            title={logic.t('button.save')}
            onClick={() => {
              logic.saveHandler();
            }}
          />
          <Button
            onClick={() => handleModalConfirm()}
            disabled={logic.isSaveing || props.type === 'create'}
            loading={logic.isDeleting}
            type="danger"
            title={logic.t('button.delete')}
          />
        </div>
        <Modal
          closeIcon={<Icon className="closeIcon" name="close"></Icon>}
          footer={null}
          centered={true}
          destroyOnClose
          title={logic.t('test')}
          onCancel={() => {
            logic.setTestOpen(false);
          }}
          visible={logic.testOpen}
        >
          <ApiTestForm
            apiProps={logic.ApiStore.basicForm}
            onClose={() => {
              logic.setTestOpen(false);
            }}
          />
        </Modal>
      </styled.Details>
    </ThemeProvider>
  );
}

export default observer(Details);
