import React, { memo, ReactNode, useEffect } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useSourceFormHook from './SourceForm.hook';
import * as styled from './SourceForm.style';
import Form from '@plasma/ui.input.form';
import Col from '@plasma/ui.layout.col';
import TextInput from '@plasma/ui.input.text-input';
import FormField from '@plasma/ui.input.form-field';
import Select from '@plasma/ui.input.select/dist/select';
import { ServiceType } from '../../../../models/enums/ServiceType';
import SwaggerForm from './forms/SwaggerForm';
import GrpcForm from './forms/GrpcForm';
import { SwaggerService } from '../../../../models/types/swaggerService';
import { grpcService } from '../../../../models/types/grpcService';
import AutoComplete from '@plasma/ui.input.auto-complete';
import { Collapse } from 'antd';
import AdditionalUrlList from './AdditionalUrlList/AdditionalUrlList';

export interface SourceFormProps {
  className?: string;
  existingGroups: {
    value: string;
    label: string;
  }[];
  onChange?: any;
  serviceType: ServiceType;
  setServiceType: any;
  additionalUrlCount:number,
  initialValues?: SwaggerService | grpcService;
  isUrlInvalid: boolean;
  documentHistory: { id: string; version: string; title: string; inUse: boolean }[];
}

type Props = SourceFormProps & ThemeOverride;

function SourceForm(props: Props) {
  const { className, size, themeMode } = props;
  const { Panel } = Collapse;
  const logic = useSourceFormHook(props);

  const forms = [
    {
      key: ServiceType.Swagger,
      element: (
        <SwaggerForm
          initialValue={(props.initialValues as SwaggerService)?.swaggerSource}
          swaggerSources={logic.swaggerSources}
          documentHistory={props.documentHistory ?? []}
        />
      ),
    },
    { key: ServiceType.gRPC, element: <GrpcForm /> },
  ];

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.SourceForm className={`configure-source ${className ?? ''}`.trim()}>
        <Form
          initialValues={logic.initialValues as any}
          onChange={(x) => {
            if (props.onChange) {
              props.onChange(x);
            }
          }}
        >
          <Col span={5}>
            <FormField
              className="group-name-field"
              required
              label={logic.t('serviceForm.groupName')}
              name={'groupName'}
            >
              <AutoComplete options={props.existingGroups} />
            </FormField>
          </Col>
          <Col span={8}>
            <FormField required label={logic.t('serviceForm.serviceUrl')} name="url">
              <TextInput status={props.isUrlInvalid ? 'error' : undefined} className="url-field" kind="outlined" />
            </FormField>
          </Col>
          <Col span={8}>
            <Collapse ghost>
              <Panel className='collapse-panel' header={`${logic.t('serviceForm.additionalUrls')} (${props.additionalUrlCount})`} key="1">
              <Col span={8}>
              <FormField className="balancing-field" required label={logic.t('serviceForm.balancing-policy')} name="balancingPolicy">
              <Select className='select-field'   options={logic.balancingPolicies} />
            </FormField>
            </Col>
                <FormField label={logic.t('serviceForm.urls')} required name="additionalUrls">
                  <AdditionalUrlList value={[]} />
                </FormField>
              </Panel>
            </Collapse>
          </Col>
          <Col span={2}>
            <FormField className="select-field" required label={logic.t('serviceForm.type')} name="type">
              <Select onSelect={props.setServiceType} options={logic.serviceTypes} />
            </FormField>
          </Col>
          {forms.find((x) => x.key === props.serviceType)?.element}
        </Form>
      </styled.SourceForm>
    </ThemeProvider>
  );
}

export default SourceForm;
