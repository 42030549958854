const transformTableGrouping = (inputString: string) => {
  const keyValuePairs = inputString.replace('>',',').match(/("[^"]*?"|[^,]*?)(?=(,|$))/g);
  const transformedPairs = [];
  if (keyValuePairs)
    for (const pair of keyValuePairs) {
      const [key, value] = pair.split(/:(?=(?:(?:[^"]*"){2})*[^"]*$)/);
if(key && value)
      transformedPairs.push(`"${key.trim()}":"${value.trim()}"`);
    }
  const transformedString = `{${transformedPairs.join(', ')}}`;
  return transformedString;
};

export { transformTableGrouping };
