import React, { memo, ReactNode } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import * as styled from './CodeEditor.styles';
import { Editor, OnChange, OnValidate } from '@monaco-editor/react';

export interface CodeEditorProps {
  children?: ReactNode;
  title: string;
  onValidate?: OnValidate;
  value?: string;
  onChange?: OnChange;
  className?: string;
  style?: React.CSSProperties;
}

type Props = CodeEditorProps & ThemeOverride;

function CodeEditor(props: Props) {
  const { className, style, size } = props;


  return (
    <ThemeProvider selectedSize={size}>
      <styled.CodeEditor className={`code-editor ${className ?? ''}`.trim()} style={style}>
        <span className="editor-title">{props.title}</span>

        <Editor
          value={props.value}
          options={{
            minimap: {
              enabled: false,
            },
          }}
          onChange={props.onChange}
          onValidate={props.onValidate}
          className="editor-window"
          theme="vs-light"
          language="javascript"
        />
      </styled.CodeEditor>
    </ThemeProvider>
  );
}

export default memo(CodeEditor) as unknown as typeof CodeEditor;
