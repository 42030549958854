import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.8);
  z-index: 10;
`;

interface Props {
  visible: boolean;
  className?: string;
  indicator?: React.ReactNode;
}

export default function LoadingOverlay(props: Props) {
  return <>{props.visible ? <Container className={props.className}>{props.indicator}</Container> : null}</>;
}
