import { createContext, useContext } from 'react';
import ApiStore from './ApiStore';
import LayoutStore from './LayoutStore';

interface Store {
  ApiStore: ApiStore;
  LayoutStore: LayoutStore;
}

const store: Store = {
  ApiStore: new ApiStore(),
  LayoutStore: new LayoutStore(),
};

const StoreContext = createContext(store);

export const useStore = () => useContext(StoreContext);
