import styled from 'styled-components';

export const ApiCreateForm = styled.div`
  width: 100%;
  height: 100%;

  overflow: visible;
  .plasma-text-area {
    resize: none;
  }
  .form {
    width: 50%;
    min-width: 840px;
    .request-body-creator{
    }
    .description-field{
      min-height: 150px;
    }
    .summary-field{
      min-height: 150px;
    }
    .form-body {
      .ant-row.plasma-row {
        margin: 0;
      }
      .ant-col {
        padding-left: 0px !important;
      }
      height: 100%;
      .lastRow {
        height: 100%;
        .parameter-creator {
          .listContainer {
            flex-basis: 0;
            border: 1px solid #d9d9d9;
          }
        }
        .plasma-form-field {
          height: 100%;
          min-height: 250px;
        }
        .ant-form-item-control {
          .ant-form-item-control-input {
            height: 100%;
            .ant-form-item-control-input-content {
              height: 100%;
              > :first-child {
                height: 100%;
                > .ant-input {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
`;
