import React, { useCallback, useMemo, useState } from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import help from './api-mapping.help';
import { translations } from '../../../../i18n';
import { ApiMappingProps } from './api-mapping';
import { ProcessorService } from '../../../../models/types/ProcessorService';
import { pathToParams } from '../../../../utils/pathToQueryParams';
import { CodeParser } from '../../../../utils/extractContextVariableNames';
import { DownstreamEndpointShort } from '../../../../models/types/downstreamEndpointShort';
import { Util } from '../ServiceList/service-list';

function useApiMappingHook(props: ApiMappingProps) {
  useResourceBundle(translations, undefined, '09a0ddc9-4b04-400d-af57-9fa01995ceda');
  useHelpBundle(help);

  const { t } = useTranslation();

  // State for the dragged item of the endpoint list
  const [draggedItem, setDraggedItem] = useState<any>();

  const onDragItem = (item: DownstreamEndpointShort | Util | undefined) => {
    if (item?.id !== draggedItem?.id) setDraggedItem(item);
  };

  /* Function to get a mapped element by its ID (e.g. mapped endpoint in the pipeline,
   useCallback for updating due to outer changes in the data that doesnot affect the mapping layout)*/
  const getMappedElement = useCallback(
    (id: string) => {
      return (
        props.store.mappedEndpointServices.find((x) => x.id === id) ?? props.store.processors.find((x) => x.id === id)
      );
    },
    [props.store.processors,props.store.mappedEndpointServices],
  );

  // Function to get a root element by its ID (e.g. endpoint of the endpoint list)
  const getRootElement = useCallback(
    (id: string) => {
      return (
        props.store.endpointServiceList.find((x) => x.id === id) ?? props.store.processors.find((x) => x.id === id)
      );
    },
    [props.store.processors],
  );

  // Variable context for parameter mapping of the selected element
  const selectedContext = useMemo(() => {
    // Find the index of the selected element in the pipeline
    let index = props.store.pipeline.findIndex((x) => x.i === props.store.selectedElementId);

    // Extract path parameters from the basic form path
    let pathParams = pathToParams(props.store.basicForm.path);
    let params = props.store.basicForm.requestParameters.map((p) => ({ value: p.name, label: p.name }));
    // [
    //   ...pathParams.paths.map((x) => ({ label: x, value: x })),
    //   ...pathParams.queries.map((q) => ({ label: q.query, value: q.query })),
    // ];

    // Get previous services in the pipeline
    let previousServices = props.store.pipeline.filter((x, ii) => ii < index).map((x) => getMappedElement(x.i)!);

    // Filter processors from previous services
    const allProcessors = [...previousServices].filter((s) => 'sourceCode' in s) as ProcessorService[];

    // Extract context variable names from source code
    var paramArray =  allProcessors
      .map((x) => {
        try {
          const extractedNames = CodeParser.extractContextVariableNames(x.sourceCode ?? '');
          return extractedNames;
        } catch {
          return [];
        }
      })
      .filter((x) => x !== undefined)
      .flat()
      .map((x: string) => ({ label: x, value: x }))
      .concat(params)
      .filter((x) => x.label !== 'nextBody')
      return paramArray.filter((obj, index) => paramArray.findIndex(item => item.value === obj.value) === index);
  }, [props.store.selectedElementId]);

  // Root element of the selected pipeline element
  const selectedElement = useMemo(() => {
    if (props.store.selectedElementId) return getRootElement(props.store.selectedElementId.replace('{L}', ''));
    return null;
  }, [props.store.selectedElementId, props.store.processors]);

  // Selected pipeline element
  const selectedMappedEndpoint = useMemo(() => {
    if (props.store.selectedElementId) return getMappedElement(props.store.selectedElementId);
    return null;
  }, [props.store.selectedElementId, props.store.mappedEndpointServices]);

  return {
    t,
    onDragItem,
    draggedItem,
    setDraggedItem,
    selectedElement,
    selectedMappedEndpoint,
    selectedContext,
    getRootElement,
    getMappedElement,
  };
}

export default useApiMappingHook;
