import { UploadFile } from 'antd/lib/upload/interface';
import { ServiceType } from '../enums/ServiceType';
import { SwaggerSource } from '../enums/SwaggerSource';

export class grpcService {
  id: string | null;
  groupName: string;
  type: ServiceType.gRPC;
  url: string;
  additionalUrls: string[];

  constructor(id?: string | null, groupName: string = '', url: string = '', additionalUrls = []) {
    this.id = null;
    this.groupName = groupName;
    this.type = ServiceType.gRPC;
    this.url = url;
    this.additionalUrls = additionalUrls;
  }
}
