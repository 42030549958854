import { Api } from '../types/api';
import { endpointParameter } from '../types/endpointParameter';
import { requestBody } from '../types/requestBody';
import { response } from '../types/response';

class ApiDto {
  namespace: string;
  name: string;
  tag: string;
  description: string;
  status: boolean;
  protocol: string;
  method: string;
  path: string;
  pipeline: any;
  requestBodyScheme: string;
  validateBody: boolean;
  summary: string;
  requestParameters: endpointParameter[];
  responses: response[];
  requestBody:requestBody;

  constructor(apiData: Api, pipeline: any) {
    this.namespace = apiData.namespace;
    this.name = apiData.name;
    this.description = apiData.description;
    this.status = apiData.status;
    this.protocol = apiData.protocol;
    this.method = apiData.method;
    this.path = apiData.path;
    this.tag = apiData.tag;
    this.pipeline = pipeline;
    this.requestBodyScheme = "";
    this.requestBody = apiData.requestBody;
    this.validateBody = apiData.validateBody;
    this.summary = apiData.summary;
    this.requestParameters = apiData.requestParameters;
    this.responses = apiData.responses;
  }
}

export { ApiDto };
