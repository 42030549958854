import styled from 'styled-components';

export const EndpointSelector = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tableContainer {
    min-height: 300px;
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;

    .ant-spin {
      height: 100% !important;
    }

    [role='table']:first-child {
      height: 100% !important;
      background-color: #ffffff;

      > :not([role]) {
        height: 100%;
      }

      > :first-child {
        > [role='rowgroup'] {
          height: calc(100% - 40px) !important;
        }
      }
    }
  }
`;
