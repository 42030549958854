import { SwaggerService } from '../types/swaggerService';
import { DownstreamService } from '../types/downstreamEndpoint';
import { ServiceType } from '../enums/ServiceType';
import { LoadBalancingPolicies } from '../enums/LoadBalancingPolicies';

class EndpointGroup {
  id: string | null;
  groupName:string;
  name: string;
  serviceUrl: string;
  additionalUrls: string[];
  balancingPolicy:LoadBalancingPolicies;
  definitionPath: string | null;
  type: ServiceType;

  constructor(endpointGroup: SwaggerService) {

    this.id = endpointGroup.id;
    this.groupName = endpointGroup.groupName;
    this.name = endpointGroup.serviceName;
    this.serviceUrl = endpointGroup.url;
    this.additionalUrls = endpointGroup.additionalUrls
    this.balancingPolicy = endpointGroup.balancingPolicy
    this.definitionPath = endpointGroup.swaggerPath === '' ? null : endpointGroup.swaggerPath;
    this.type = endpointGroup.type;
  }
}

class DownstreamEndpoint {
  id: string | null;
  method: string;
  name: string;
  path: string;
  tag: string;
  parameterJson: string | null;
  requestBodyJson: string | null;
  responsesJson: string | null;
  description: string;
  summary: string;

  constructor(endpoint: DownstreamService) {
    this.id = endpoint.id ?? null;
    this.method = endpoint.method.toUpperCase();
    this.name = endpoint.name;
    this.path = endpoint.path;
    this.tag = endpoint.tag;
    this.parameterJson = endpoint.parameters ? JSON.stringify(endpoint.parameters) : null;
    this.requestBodyJson = endpoint.requestBody ? JSON.stringify(endpoint.requestBody) : null;
    this.responsesJson = endpoint.responses ? JSON.stringify(endpoint.responses) : null;
    this.description = endpoint.description;
    this.summary = endpoint.summary;
  }
}
class EndpointDto extends EndpointGroup {
  downStreamEndpoints: DownstreamEndpoint[];
  documentations: { data: string; version: string,title:string, inUse:boolean }[];
  constructor(group: SwaggerService, endpoints: DownstreamService[], document: { data: string; version: string,title:string, inUse:boolean }) {
    super(group);
    this.documentations = [document];
    this.downStreamEndpoints = endpoints.map((x) => new DownstreamEndpoint(x));
  }
}

type EndpointInputDto = EndpointGroup & {
  id: string;
  documentations: {id:string, data: string; version: string,title:string, inUse:boolean }[];
  downstreamEndpoints: (DownstreamService & { id: string; inUse: boolean })[];
};

export { EndpointDto};
export type { EndpointInputDto };
