import styled from 'styled-components';

export const SourceForm = styled.div`
  width: 100%;
  height: 100%;
  .group-name-field {
    min-width: 210px;
  }

  .url-field {
    min-width: 330px;
  }
  .collapse-panel{
    min-width: 330px;
  }
  .select-field {
    min-width: 120px;
  }
  .balancing-field {
    min-width: 200px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.ant-collapse-content > .ant-collapse-content-box{
  padding:0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
  padding:0;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
`;
