import Keycloak from "keycloak-js";



const keycloak = new Keycloak({
    url: window.env.KEYCLOAK_URL,
    realm: window.env.KEYCLOAK_REALM,
    clientId: window.env.KEYCLOAK_CLIENT,
});




export default keycloak;
