import styled from 'styled-components';

export const ProcessorForm = styled.div`
  width: 100%;
  height: 100%;
  .plasma-text-area {
    resize: none;
  }
  .content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

    .form {
      width: 100%;

      .type-select {
        min-width: 150px;
      }
      .ant-row.plasma-row {
        margin: 0;
      }
      .ant-col {
        padding-left: 0px !important;
        padding: 0px !important;
      }
      .code-input-row {
        flex-grow: 3;
        flex-basis: 0;
        .code-input {
          height: 100%;
        }
        .code-input .ant-form-item-control {
          height: calc(100% - 30px);
        }
        .ant-form-item-control :not(.ant-form-item-label) {
          height: 100%;
        }
      }
    }

    .editor {
      height: calc(100% - 167px);
      width: 100%;
    }
  }
  .help-header{
    margin-top: 10px;
  }
`;
