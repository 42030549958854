import { useLocation, useParams } from '@plasma/ui.application.router';
import Breadcrumb from '@plasma/ui.navigation.breadcrumb';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../../stores/store';

const BreadcrumbWrapper = styled.div`
  position: sticky;
  padding-left: 10px;
  background-color: lightgray;
  .breadcrumb {
    min-height: 48px;
  }
`;

interface Props {
  children?: React.ReactNode;
}

export default observer(function Breadcrump() {
  const { LayoutStore } = useStore();
  const { id,namespace } = useParams();
  const routes = [
    {
      path: '/apis',
      title: 'APIs',
      children: [
        {
          path: `/apis/swagger`,
          title: 'Swagger',
          children: [
            {
              path: `/apis/swagger/${namespace}`,
              title: namespace,
            },
          ],
        },
        {
          path: `/apis/edit`,
          title: 'Edit',
          children: [
            {
              path: `/apis/edit/${id}`,
              title: LayoutStore.breadcrumbItem ?? ' ',
            },
          ],
        },

        {
          path: `/apis/create`,
          title: 'Create',
        },
        {
          path: `/apis/create-from-services`,
          title: 'Create from services',
        },
        {
          path: `/apis/export`,
          title: 'Export',
        },
      ],
    },
    {
      path: '/services',
      title: 'Services',
      children: [
        {
          path: `/services/edit`,
          title: 'Edit',
          children: [
            {
              path: `/services/edit/${id}`,
              title: LayoutStore.breadcrumbItem ?? ' ',
            },
          ],
        },
        {
          path: `/services/swagger`,
          title: 'Swagger',
          children: [
            {
              path: `/services/swagger/${id}`,
              title: LayoutStore.breadcrumbItem ?? ' ',
            },
          ],
        },
        {
          path: `/services/create`,
          title: 'Import Services',
        },
      ],
    },
  ];
  let { pathname } = useLocation();

  return (
    <BreadcrumbWrapper>
      <Breadcrumb   homeIcon={undefined} hideFirstSeparator={true} className="breadcrumb" routes={routes} currentPath={pathname} />
    </BreadcrumbWrapper>
  );
});
