import React, { memo, ReactNode } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Col from '@plasma/ui.layout.col';
import useEndpointInfoHook from './EndpointInfo.hook';
import * as styled from './EndpointInfo.styles';
import Row from '@plasma/ui.layout.row';
import Form from '@plasma/ui.input.form';
import FormField from '@plasma/ui.input.form-field';

import { Requestbody, Requestparameter, Responsebody } from './Bodies';
import { toJS } from 'mobx';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';

export interface EndpointInfoProps {
  children?: ReactNode;
  /*
   * Defines custom className
   */
  className?: string;
  /*
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
  enpoint?: DownstreamService;
}

type Props = EndpointInfoProps & ThemeOverride;

function EndpointInfo(props: Props) {
  const { children, className, style, size, themeMode } = props;
  const Textfield = ({ value, upperCase }: { value?: string; upperCase?: boolean }) => {
    if (upperCase) return <h4>{value?.toUpperCase()}</h4>;
    return <h4>{value}</h4>;
  };

  const logic = useEndpointInfoHook();
  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.EndpointInfo className={`endpoint-info ${className ?? ''}`.trim()} style={style}>
        <><Form className="form" initialValues={props.enpoint} id="apiform">
            <Row>
              <Col span={24}>
                <FormField required label={logic.t('label.name')} name="name">
                  <Textfield />
                </FormField>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormField required label={logic.t('label.service-name')} name="serviceName">
                  <Textfield />
                </FormField>
              </Col>
            </Row>
            <Row>
              <Col style={{ width: 100 }}>
                <FormField required label={logic.t('label.protocol')} name="protocol">
                  <Textfield upperCase />
                </FormField>
              </Col>
              <Col span={6}>
                <FormField required label={logic.t('label.method')} name="method">
                  <Textfield upperCase />
                </FormField>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormField required label={logic.t('label.url')} name="url">
                  <Textfield />
                </FormField>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormField required label={logic.t('label.path')} name="path">
                  <Textfield />
                </FormField>
              </Col>
            </Row>
          </Form><div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <Requestparameter value={props.enpoint?.parameters ?? undefined} />
              <Requestbody value={props.enpoint?.requestBody} />
              <Responsebody value={props.enpoint?.responses} />
            </div></>
      </styled.EndpointInfo>
    </ThemeProvider>
  );
}

export default memo(EndpointInfo) as unknown as typeof EndpointInfo;
