import React from 'react';

export const JsLogo = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="600.000000pt"
    height="600.000000pt"
    viewBox="0 0 600.000000 600.000000"
    preserveAspectRatio="xMidYMid meet"
    style={{
      backgroundColor: '#F7DF1E',
    }}
    {...props}
  >
    <g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)" fill="#5" stroke="none">
      <path d="M4426 3269 c-383 -50 -641 -292 -686 -644 -24 -189 26 -408 129 -560 37 -54 154 -165 222 -211 97 -64 214 -124 389 -199 345 -148 425 -202 465 -314 32 -91 14 -178 -51 -250 -104 -116 -332 -151 -531 -81 -114 40 -239 145 -312 264 -13 20 -29 36 -35 34 -27 -7 -445 -254 -446 -263 0 -23 103 -168 169 -238 207 -217 491 -327 845 -327 234 0 424 44 576 134 253 149 371 379 357 696 -10 227 -65 358 -212 506 -138 139 -253 207 -613 363 -211 92 -287 136 -344 199 -45 49 -61 106 -56 188 7 107 73 180 189 210 54 14 76 15 137 5 40 -6 91 -20 114 -32 50 -26 135 -110 163 -162 11 -21 25 -35 32 -33 32 13 423 270 423 278 0 26 -102 157 -176 227 -130 124 -281 189 -484 211 -110 11 -168 11 -264 -1z" />
      <path d="M2973 3242 l-273 -2 0 -968 c0 -583 -4 -994 -10 -1035 -26 -182 -112 -257 -291 -257 -137 0 -237 66 -323 214 -21 36 -42 66 -47 66 -6 0 -422 -247 -447 -266 -16 -12 88 -174 161 -251 101 -107 246 -192 402 -235 112 -31 421 -33 535 -4 308 79 478 254 557 576 15 57 17 188 20 1109 2 574 0 1047 -4 1050 -4 3 -131 5 -280 3z" />
    </g>
  </svg>
);
