import React, { useState } from 'react';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import translations from './i18n';
import { endpointParameter } from '../../../models/types/endpointParameter';
import { ParameterCreatorProps } from './parameter-creator';

function useParameterCreatorHook(props: ParameterCreatorProps) {
  useResourceBundle(translations, undefined, '85195d4b-2472-446d-ba65-234bdd11d607');

  const { t } = useTranslation();

  const [internalParams, setInternalParams] = useState<endpointParameter[]>([]);
  const [enumModal, setEnumModal] = useState<number | undefined>();
  const [tempEnum, setTempEnum] = useState<string[]>([]);
  const [descrModal, setDescrModal] = useState<number | undefined>();
  const [tempDescr, setTempDescr] = useState<string>('');

  const params = props.value ?? internalParams;

  const setState = (state: endpointParameter[]) => {
    if (props.onChange) {
      props.onChange(state);
    }
    if (!props.value) {
      setInternalParams(state);
    }
  };

  const addHandler = () => {
    let newList = [...params];
    newList.push({ name: '', in: null, required: false, description: '', isArray: false, enum: [], type: null });
    setState(newList);
  };

  const emptyData = [{ name: '', in: null, required: false, description: '', isArray: false, enum: [], type: null }];

  const dataSource = params.length > 0 ? params : emptyData;
  const isEmpty = params.length === 0;
  const removeHandler = (index: number) => {
    let newList = [...params];
    newList = newList.filter((element, ii) => ii !== index);
    setState(newList);
  };

  const changeHandler = (index: number, parameter: keyof endpointParameter, value: unknown) => {
    const newList = [...params];
    let item = newList.at(index);
    if (item != undefined) {
      (item[parameter] as unknown) = value;
      setState(newList);
    }
  };

  const tempEnumChangeHandler = (action: 'add' | 'delete' | 'update', index?: number, value?: string) => {
    let newList = [...tempEnum];
    switch (action) {
      case 'add':
        newList.push('');
        break;
      case 'delete':
        newList = newList.filter((element, ii) => ii !== index);
        break;
      case 'update':
        newList[index!] = value!;
        break;
      default:
        break;
    }
    setTempEnum(newList);
  };

  const tempDescrChangeHandler = (value: string) => {
    setTempDescr(value);
  };

  const saveTempDescr = () => {
    const newList = [...params];
    let item = newList.at(descrModal!);
    if (item != undefined) {
      item.description = tempDescr;
      setState(newList);
      setTempDescr('');
      setDescrModal(undefined);
    }
  };

  const saveTempEnum = () => {
    const newList = [...params];
    let item = newList.at(enumModal!);
    if (item != undefined) {
      item.enum = tempEnum;
      setState(newList);
      setTempEnum([]);
      setEnumModal(undefined);
    }
  };

  const openDescrModal = (index: number | undefined) => {
    setTempDescr(params[index!].description);
    setDescrModal(index);
  };

  const closeDescrModal = () => {
    setTempDescr('');
    setDescrModal(undefined);
  };

  const openEnumModal = (index: number | undefined) => {
    setTempEnum(params[index!].enum);
    setEnumModal(index);
  };

  const closeEnumModal = () => {
    setTempEnum([]);
    setEnumModal(undefined);
  };
  return {
    t,
    dataSource,
    addHandler,
    removeHandler,
    changeHandler,
    enumModal,
    openEnumModal,
    tempEnum,
    tempEnumChangeHandler,
    saveTempEnum,
    closeEnumModal,
    tempDescrChangeHandler,
    saveTempDescr,
    tempDescr,
    descrModal,
    openDescrModal,
    closeDescrModal,
    isEmpty,
  };
}

export default useParameterCreatorHook;
