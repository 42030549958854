import React, { memo, ReactNode, useMemo } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';

import useEndpointSelectorHook from './EndpointSelector.hook';
import * as styled from './EndpointSelector.styles';
import { ColumnType } from '@plasma/ui.display.data-grid';
import Checkbox from '@plasma/ui.input.checkbox';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';
import CustomDataGrid, { ColumnType2 } from '../../../core/CustomDataGrid/CustomDataGrid';
import EditField from '../../../shared/EditField/EditField';

export interface EndpointSelectorProps {
  children?: ReactNode;
  data: (DownstreamService & { selected: boolean; inUse: boolean })[];
  setServices: (value: (DownstreamService & { selected: boolean; inUse: boolean })[]) => void;
  className?: string;
  style?: React.CSSProperties;
  includesUsedServices: boolean | undefined;
  preSelected: string[];
}

type Props = EndpointSelectorProps & ThemeOverride;

function EndpointSelector(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useEndpointSelectorHook(props);
  const columns2: ColumnType2<DownstreamService & { selected: boolean; inUse: boolean }>[] = [
    {
      title: logic.t('header.tag'),
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: logic.t('header.method'),
      dataIndex: 'method',
      render: (value, row) => {
        if (row.depth > 0) return value.toUpperCase();
        else return null;
      },
      key: 'method',
    },

    {
      title: logic.t('header.name'),
      dataIndex: 'name',
      width:300,
      render: (value, row) => {
        if (row.depth > 0)
          return (
            <EditField

              value={(value ?? '')}
              onChange={(e) => {
                onCellUpdate(row.originalIndex, e ?? '');
              }}
            />
          );
        else return null;
      },
      key: 'name',
    },
    {
      title: logic.t('header.path'),
      dataIndex: 'path',
      key: 'path',
    },
  ];

  const onCellUpdate = (rowIndex: number, value: string) => {
    let newArray = [...props.data];
    let element = newArray.at(rowIndex);
    if (element) element.name = value;
    props.setServices(newArray);
  };
  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.EndpointSelector className={`select-services ${className ?? ''}`.trim()} style={style}>
        {props.includesUsedServices !== false ? (
          <h5>{logic.t('serviceSelector.description')}</h5>
        ) : (
          <h5 style={{ color: 'red' }}>{logic.t('serviceSelector.error.missingUsed')}</h5>
        )}
        <div className="tableContainer">
          {props.data ? (
            <CustomDataGrid<DownstreamService & { inUse: boolean; selected: boolean }>
              data={props.data ?? []}
              columns={columns2}
              groupNames={['tag']}
              defaultExpandGroups={['tag']}
              checkable={true}
              defaultSelected={props.preSelected}
              checkDisabled={(row) => {
                return (!props.includesUsedServices??false) || (row?.inUse??false);
              }}
              onCheck={(data, keys) => {
                const newArr = [...props.data].map((d, ii) => ({
                  ...d,
                  selected: keys.some((k) => k === ii.toString()),
                }));
                props.setServices(newArr);
              }}
            ></CustomDataGrid>
          ) : null}
        </div>
      </styled.EndpointSelector>
    </ThemeProvider>
  );
}

export default EndpointSelector as unknown as typeof EndpointSelector;
