import React, { ReactNode, useMemo } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import { v4 as uuidv4 } from 'uuid';
import useMappingAreaHook from './mapping-area.hook';
import * as styled from './mapping-area.styles';
import Xarrow, { Xwrapper } from 'react-xarrows';
import GridLayout from 'react-grid-layout';
import NoData from '../../../core/NoData/NoData';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';
import ScrollContainer from '../../../core/ScrollContainer/ScrollContainer';

export interface MappingAreaProps {
  className?: string;
  cardElement: ReactNode;
  style?: React.CSSProperties;
  draggedItem?: DownstreamService | undefined;
  onAddEndpointService: (id: string, layout: ReactGridLayout.Layout[]) => void;
  onAddProcessor: (index: number, type?: string, id?: string) => void;
  mappingLayout: ReactGridLayout.Layout[];
  onUpdateMapping: (layout: ReactGridLayout.Layout[]) => void;
  onBackgroundClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}
const headShapeArrow2 = { svgElem: <path d="M 0 0 L 1 0.5 L 0 1" />, offsetForward: 0.25 };
type Props = MappingAreaProps & ThemeOverride;

function MappingArea(props: Props) {
  const { className, style, size, themeMode } = props;
  const logic = useMappingAreaHook(props);

  const Arrows = useMemo(() => {
    return props.mappingLayout.map((x, ii) =>
      props.mappingLayout[ii + 1] !== undefined ? (
        <Xarrow
          color={'black'}
          key={uuidv4()}
          strokeWidth={20}
          headSize={2}
          headShape={headShapeArrow2}
          start={props.mappingLayout.at(ii)?.i!}
          end={props.mappingLayout.at(ii + 1)?.i!}
        />
      ) : null,
    );
  }, [props.mappingLayout, logic.onFinalChange, logic.showConnections]);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.MappingArea
        onClick={props.onBackgroundClick}
        className={`mapping-area ${className ?? ''}`.trim()}
        style={style}
      >
        <ScrollContainer className='scrollContainer'  >
        <GridLayout
          draggableHandle=".service-element"
          className="gridla"
          layout={props.mappingLayout}
          isDroppable={true}
          isResizable={false}
          cols={1}
          onDrop={() => {
            logic.onFinalChange();
          }}
          onDrag={(x) => {
            logic.setShowConnection(false);
            logic.onLayoutChange(x);
          }}
          onDragStop={() => {
            logic.onFinalChange();
          }}
          isDraggable
          margin={[0, 60]}
          width={398}
          rowHeight={150}
        >
          {props.mappingLayout.map((x, ii) => (
            <div style={{ padding: 10 }} id={x.i} key={x.i} data-grid={{ x: x.x, y: x.y, w: x.w, h: x.h }}>
              {React.isValidElement(props.cardElement)? React.cloneElement<any>(props.cardElement, {
                index: x.y,
                elementId: x.i,
                className: `service-element`,
              }):null}
            </div>
          ))}
        </GridLayout>

        {props.mappingLayout.length < 1 ? (
          <NoData
            className="place-holder"
            icon="add_circle_outline"
            text={logic.t('mapping.mappingArea.placeholder')}
          />
        ) : null}
        <Xwrapper>{logic.showConnections ? [Arrows] : null}</Xwrapper>
        </ScrollContainer>
      </styled.MappingArea>
    </ThemeProvider>
  );
}

export default MappingArea;
