import baseStyled, { ThemedCssFunction, ThemedStyledInterface, css as baseCss } from 'styled-components';

import { OverviewTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<OverviewTheme>;
const css = baseCss as ThemedCssFunction<OverviewTheme>;

export const Overview = styled.div`
  width: 100%;
  height: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;


  .action-bar {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .toolbar {
    height: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .searchInput {
      width: 200px;
    }
    .buttonContainer {
      margin-left: auto;
    }
  }

  .tableContainer {
    position: relative;
    min-height: 0px;
    flex-grow: 1;

    .ant-spin {
      height: 100% !important;
    }

    [role='table']:first-child {
      height: 100% !important;
      background-color: #ffffff;

      > :not([role]) {
        height: 100%;
      }

      > :first-child {
        > [role='rowgroup'] {
          height: calc(100% - 40px) !important;
        }
      }
    }
  }
`;
