import { useMemo } from 'react';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import { SwaggerService } from '../../../models/types/swaggerService';
import { grpcService } from '../../../models/types/grpcService';
import { EndpointInputDto } from '../../../models/dtos/EndpointDto';
import { ServiceType } from '../../../models/enums/ServiceType';

export default function useSelectionStep(
  initialDataSource: void | EndpointInputDto | undefined,
  type: 'create' | 'update',
  serviceSource: SwaggerService | grpcService,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  services: (DownstreamService & {
    selected: boolean;
    inUse: boolean;
  })[],
) {
  const preSelected = services
    ?.map((s, ii) =>
      initialDataSource?.downstreamEndpoints.some((x) => x.path === s.path && x.method === s.method)
        ? ii.toString()
        : undefined,
    )
    .filter((s) => s !== undefined) as string[];

  //Check
  const includesUsedServices = useMemo(() => {
    if (services && type === 'update')
      return initialDataSource?.downstreamEndpoints
        .filter((d) => d.inUse)
        .every((selectedEndpoint) =>
          services.some(
            (endpoint) =>
              selectedEndpoint.parameters?.every((p) =>
                endpoint.parameters?.some(
                  (ep) =>
                    ep.name === p.name &&
                    ep.in === p.in &&
                    ep.required == p.required &&
                    ep.schema.type == p.schema.type,
                ),
              ) &&
              endpoint.method === selectedEndpoint.method &&
              endpoint.path === selectedEndpoint.path,
          ),
        );
    else return true;
  }, [services, initialDataSource]);

  const selectedServices = services?.filter((s) => s.selected);

  const step2Check = (value?: SwaggerService | grpcService) => {
    switch (value?.type) {
      case ServiceType.Swagger:
        if (selectedServices) return selectedServices?.length > 0 ? selectedServices?.every((x) => x.name?.length > 0 && x.tag?.length > 0) : false;
        break;
      case ServiceType.gRPC:
        return false;
      default:
        return false;
    }
  };

  const step2Done = useMemo(() => {
    return step2Check(serviceSource);
  }, [services]);

  const secondStepHandler = () => {
    setStep(2);
  };

  return {
    preSelected,
    selectedServices,
    step2Done,
    includesUsedServices,
    secondStepHandler,
  };
}
