import notification from '@plasma/ui.feedback.notification/dist/notification';
import React from 'react';
import { useState, useEffect } from 'react';

type Status = 'pending' | 'success' | 'error';

export const promiseWrapper = <T>(promise: Promise<T | undefined>) => {
  let status: Status = 'pending';
  let result: T | undefined;

  const s = promise.then(
    (value) => {
      status = 'success';
      result = value;
    },
    (error) => {
      status = 'error';
      result = error;
    },
  );

  return () => {
    switch (status) {
      case 'pending':
        throw s;
      case 'success':
        return result;
      case 'error':
        throw result;
      default:
        throw new Error('Unknown status');
    }
  };
};

// Should be used to do a api call on first render
function useGetInitialData<T>(call: () => Promise<T>, suspense: boolean, condition: boolean) {
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (isMounted && condition) {
      getData();
    }

    return () => {
      setIsMounted(false);
    };
  }, [condition]);

  const getData = async () => {
    if (!suspense) {
      setLoading(true);
      try {
        const response = await call();
        setData(response);
      } catch (error) {
        setError(error as Error);
        notification.error('Error', error as string, undefined, 'small', 3);
      } finally {
        setLoading(false);
      }
    } else {
      const promise = call()
        .then((response) => response)
        .catch((error) => {
          setError(error as Error);
          notification.error('Error', error as string, undefined, 'small', 3);
          return undefined;
        });
      const dataWrapper = promiseWrapper<T>(promise);
      setData(dataWrapper);
    }
  };

  const refetch = async () => {
    await getData();
  };

  return { data, setData, loading, refetch, error };
}

// Should be used to do a api call at any time
function useApiCall<T>(call: (() => Promise<any>) | null, successMessage: string | null, errorMessage: string | null) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const getData = async (promise: () => Promise<any>) => {
    setDone(false);
    setLoading(true);
    try {
      await promise().then((response) => {
        if (successMessage) notification.success('Success', successMessage, undefined, 'small', 3);
        setDone(true);
        setData(response as T);
      });
    } catch (error) {
      setError(error as Error);
      errorMessage
        ? notification.error('Error', errorMessage, undefined, 'small', 3)
        : notification.error((error as any)?.response?.data?.message, (error as any)?.response?.data?.description, undefined, 'small', 3);
    } finally {
      setLoading(false);
    }
  };

  const execute = async (promise?: () => Promise<any>) => {
    if (promise) await getData(promise);
    else if (call) await getData(call);
  };

  return { data, setData, loading, execute, done, error };
}

const dataHooks = { useGetInitialData, useApiCall };

export { dataHooks };
