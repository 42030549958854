import React, { useMemo, useState } from 'react';
import * as styled from './BodyModalContent.styles';
import TextArea from '@plasma/ui.input.text-area';
import TextInput from '@plasma/ui.input.text-input';
import { AutoComplete } from 'antd';
import { bodyInfo } from '../../../../models/types/bodyInfo';
import beautify from 'js-beautify';
import { contentTypes } from '../../constants';
export interface BodyModalContentProps {
  value: bodyInfo;
  changeHandler: (value: bodyInfo) => void;
  lock?: boolean;
}

export default function BodyModalContent(props: BodyModalContentProps) {
  const [initialBeautify, setInitialBeautify] = useState(false);
  const textValue = useMemo(() => {
    if (initialBeautify) {
      return props.value.content ?? '';
    }
    setInitialBeautify(true);
    return beautify(props.value.content ?? '', { indent_size: 4 });
  }, [props.value.content]);
  return (
    <styled.BodyModalContent>
      <AutoComplete
        value={props.value.type}
        className="content-field"
        options={contentTypes}
        onChange={(value) => {
          if (!props.lock) props.changeHandler({ type: value, content: props.value.content });
        }}
      />
      <TextArea
        className="body-field"
        spellCheck={false}
        onChange={(e) => {
          if (!props.lock) props.changeHandler({ type: props.value.type, content: e.target.value });
        }}
        value={textValue}
      />
    </styled.BodyModalContent>
  );
}
