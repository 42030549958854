import React from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useServiceListHook from './service-list.hook';
import * as styled from './service-list.styles';
import Icon from '@plasma/ui.general.icon';
import TextInput from '@plasma/ui.input.text-input';
import { MappedEndpoint } from '../../../../models/types/mappedEnpoint';
import ScrollableArea from '@plasma/ui.layout.scrollable-area';
import Tabs from '@plasma/ui.navigation.tabs'
import { JsLogo } from '../../../core/logos/javascript';
import { DownstreamEndpointShort } from '../../../../models/types/downstreamEndpointShort';
export interface ServiceListProps {
  className?: string;
  style?: React.CSSProperties;
  onDrag?: (item: DownstreamEndpointShort | Util | undefined) => void;
  services: DownstreamEndpointShort[];
  mappedServices: MappedEndpoint[];
  onSelectElement: (id: string) => void;
  selectedElementId: string | null;
}
export type Util = {
  name:string, icon:React.JSX.Element | null,id:string
}
type Props = ServiceListProps & ThemeOverride;

function ServiceList(props: Props) {
  const { className, style, size, themeMode } = props;
  const utils:Util[] = [{name:'Processor', icon:<JsLogo className='util-logo'/>, id:"Processor"}]
  const logic = useServiceListHook(props);
  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ServiceList className={`service-list ${className ?? ''}`.trim()} style={style}>
        <TextInput
          onChange={(x) => logic.onFilterChange(x.target.value)}
          placeholder={logic.t('mapping.serviceList.search.placeholder')}
          className="searchInput"
        />
        <div className='tab-container'>
        <Tabs  type="line">
        <Tabs.TabPane className='tab-pane'  tab={logic.t('mapping.pipelineElements.tab.services')} key="1">
        <div className="search-container">
          <ScrollableArea>
            {Object.entries(logic.filteredServices).map((group) => (
              <div key={group[0]}>
                <div
                  className="parent"
                  onClick={() => {
                    logic.selectHandler(group[0]);
                  }}
                >
                  {logic.isExpanded(group[0]) ? (
                    <Icon className="icon" name="expand_more" />
                  ) : (
                    <Icon className="icon" name="chevron_right" />
                  )}
                  <span>{group[0]}</span>
                </div>
                {logic.isExpanded(group[0])
                  ?

                  Object.entries(group[1]).map((service) => (
                    <div key={`${group[0]}-${service[0]}`}>
                      <div
                        className="parent sub"
                        onClick={() => {
                          logic.selectHandler(`${group[0]}-${service[0]}`);
                        }}
                      >
                        {logic.isExpanded(`${group[0]}-${service[0]}`) ? (
                          <Icon className="icon" name="expand_more" />
                        ) : (
                          <Icon className="icon" name="chevron_right" />
                        )}
                        <span>{service[0]}</span>
                      </div>
                      {logic.isExpanded(`${group[0]}-${service[0]}`)
                        ? service[1]?.map((item, ii) => (
                            <div
                              onClick={() => {
                                props.onSelectElement("{L}"+item.id!);
                              }}
                              key={ii}
                              draggable={!logic.mappedCheck(item.id!)}
                              className={`child ${logic.mappedCheck(item.id!) ? 'placed-child' : ''} ${props.selectedElementId===("{L}"+item.id) ? 'selected-child' : ''}`}
                              onDrag={() => {
                                if (props.onDrag) props.onDrag(item);
                              }}
                              onDrop={() => {
                                if (props.onDrag) props.onDrag(undefined);
                              }}
                            >
                              <Icon className="icon" name="link" />
                              <span>{item.name}</span>
                            </div>
                          ))
                        : null}
                    </div>
                  ))
                  : null}
              </div>
            ))}
          </ScrollableArea>
        </div>
          </Tabs.TabPane>
          <Tabs.TabPane className='tab-pane'  tab={logic.t('mapping.pipelineElements.tab.utils')} key="2">
          <ScrollableArea>
            {(utils).map((item, ii) => (
         <div
         className={`child util ${props.selectedElementId===("{L}"+item.id) ? 'selected-child' : ''}`}
         draggable={!logic.mappedCheck(item.id!)}
         onClick={() => {
           props.onSelectElement("{L}"+item.id!);
         }}
         key={ii}
         onDrag={() => {

           if (props.onDrag) props.onDrag(item);
         }}
         onDrop={() => {
           if (props.onDrag) props.onDrag(undefined);
         }}
       >
        {item.icon}

         <span>{item.name}</span>
       </div>
            ))}
          </ScrollableArea>
          </Tabs.TabPane>
        </Tabs>
        </div>
      </styled.ServiceList>
    </ThemeProvider>
  );
}

export default ServiceList;
