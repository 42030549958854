import { ProcessorType } from '../enums/ProcessorType';
import { v4 as uuidv4 } from 'uuid';
import { PipelineElement } from '../enums/PipelineElement';

export class ProcessorService {
  type: PipelineElement;
  name: string;
  method: ProcessorType;
  id: string;
  sourceCode: string;
  error: boolean;
  // requestBody: string;
  // responses: string;
  constructor(id: string = uuidv4(), name: string = 'Processor', sourceCode: string = '') {
    this.name = name;
    this.type = PipelineElement.Processor;
    this.id = id;
    this.method = ProcessorType.JavaScript;
    this.sourceCode = sourceCode;
    // this.requestBody = '';
    // this.responses = '';
    this.error = false;
  }
}
