import React, { memo, ReactNode } from 'react';
import Button from '@plasma/ui.general.button';
import TextInput from '@plasma/ui.input.text-input';
import ScrollableArea from '@plasma/ui.layout.scrollable-area';
import { Icon } from '@plasma/ui.general.icon';
import * as styled from './AdditionalUrlList.styles';
import { Collapse } from 'antd';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { urlChecker } from '../../../../../utils/urlChecker';

export interface AdditionalUrlListProps {
  value: string[];
  onChange?: (value: any) => void;
  lock?: boolean;
}

export default function AdditionalUrlList(props: AdditionalUrlListProps) {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const changeHandler = (action: 'add' | 'delete' | 'update', index?: number, value?: string) => {
    const currentValue = [...props.value];
    switch (action) {
      case 'add':
        currentValue.push('');
        if (props.onChange) return props.onChange(currentValue);
        break;
      case 'delete':
        const filteredValue = currentValue.filter((x, ii) => ii !== index);
        if (props.onChange) return props.onChange(filteredValue);
        break;
      case 'update':
        if(index !==undefined && value !==undefined)
        currentValue[index] = value
        if (props.onChange) return props.onChange(currentValue);
        break;
    }
  };
  return (
    <styled.AdditionalUrlList>
          <div className="listContainer">
            <ScrollableArea className="scrollArea">
              {props.value?.map((item, ii) => (
                <div key={ii} className="listElementContainer">
                  <TextInput
                  type={!urlChecker(item) && item.length>0?"danger":undefined}
                    className="inputElement"
                    onChange={(e) => {
                      if (!props.lock) changeHandler('update', ii, e.target.value);
                    }}
                    placeholder={t("serviceForm.additionalUrl")}
                    value={item}
                  />
                  {!props.lock ? (
                    <Button
                      onClick={() => {
                        changeHandler('delete', ii);
                      }}
                      type="text"
                      icon={<Icon name="remove" />}
                    />
                  ) : null}
                </div>
              ))}
            </ScrollableArea>
          </div>
          {!props.lock ? (
            <Button
              onClick={() => {
                changeHandler('add');
              }}
              title="Add"
              className="addButton"
            />
          ) : null}
    </styled.AdditionalUrlList>
  );
}
