import React, { memo, ReactNode, useMemo, useRef } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useOverviewHook from './service-overview.hook';
import * as styled from './service-overview.styles';
import themes from './themes';
import TextInput from '@plasma/ui.input.text-input';

import DataGrid, { ColumnType } from '@plasma/ui.display.data-grid';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import PopConfirm from '@plasma/ui.feedback.pop-confirm';
import Icon from '@plasma/ui.general.icon';
import StatusIndicator from '../../../components/core/StatusIndicator/StatusIndicator';
import CustomDataGrid, { ColumnType2 } from '../../../components/core/CustomDataGrid/CustomDataGrid';
import { Button as AntButton } from 'antd';
import Button from '@plasma/ui.general.button';
import { EditOutlined, FileAddOutlined, FileTextOutlined, HistoryOutlined, UploadOutlined } from '@ant-design/icons';
import ServiceMenu from '../../../components/features/service-overview/ServiceMenu/ServiceMenu';
import ServiceHistory from '../../../components/features/service-overview/ServiceHistory/ServiceHistory';
import Secure from "@plasma/ui.application.secure"
export interface OverviewProps {
  children?: ReactNode;
  /*
   * Defines custom className
   */
  className?: string;
  /*
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = OverviewProps & ThemeOverride;

function Overview(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useOverviewHook();

//@ts-ignore
const adminRole = window.env.ADMIN_ROLE;

  const columns2: ColumnType2<DownstreamService & { inUse: boolean; serverAvailable: boolean }>[] = [
    // { dataIndex: 'id', title: 'Id', key: 'id', minWidth: 100 },
    {
      dataIndex: 'groupName',
      title: logic.t('header.group-name'),
      key: 'group-name',
      minWidth: 200,
    },
    {
      dataIndex: 'serviceName',
      title: logic.t('header.service-name'),
      key: 'name',
      minWidth: 200,
    },
    {
      dataIndex: 'version',
      title: logic.t('header.version'),
      key: 'version',
      render: (value, row) => {
        if (row.depth === 1)
          return (
            <AntButton
              danger={!row.subElements[0].version}
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                if (row.subElements[0].version) logic.navigateServiceSwagger(row.subElements[0].serviceId);
              }}
              icon={<FileTextOutlined />}
            >
              {row.subElements[0].version}
            </AntButton>
          );
        return null;
      },
    },
    {
      dataIndex: 'serverAvailable',
      title: logic.t('header.serverAvailable'),
      key: 'ds',

      render: (value, row) => {
        if (row.depth === 1)
          return <StatusIndicator active={row.subElements[0].serverAvailable} size={16} falseVaulue="" trueVaulue="" />;
        return null;
      },
    },
    {
      width: 100,
      minWidth: 100,
      title: logic.t('header.method'),
      dataIndex: 'method',
      key: 'protocol',
    },
    {
      minWidth: 200,
      title: logic.t('header.name'),
      dataIndex: 'name',
      key: 'protocol',
    },
    {
      minWidth: 250,
      title: logic.t('header.summary'),
      dataIndex: 'summary',
      key: 'protocol',
    },
    {
      minWidth: 150,
      title: logic.t('header.tag'),
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      minWidth: 100,
      width: 100,
      title: logic.t('header.inUse'),
      dataIndex: 'inUse',
      key: 'inUse',
      render: (value: boolean, row, index) => {
        if (row.depth < 2) {
          let total = row.subElements.length;
          let used = row.subElements.filter((e) => e.inUse === true).length;
          return (
            <span>
              {used}/{total}
            </span>
          );
        } else
          return (
            <>
              {value ? (
                <Icon style={{ color: 'lightgreen' }} name="check_circle_outline" />
              ) : (
                <Icon name="radio_button_unchecked" />
              )}
            </>
          );
      },
    },
    {
      width: 32,
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value, row) => {
        if (row.depth === 1)
          return (
            <ServiceMenu>
              <Secure permissions={[adminRole]}>
              <AntButton
                title={logic.t('header.edit')}
                type="link"
                icon={<EditOutlined />}
                onClick={() => logic.handleNavigate(`edit/${row.subElements[0].serviceId}`)}
              />

              </Secure>
              <ServiceMenu
                icon={<AntButton title={logic.t('button.documents')} type="link" icon={<FileTextOutlined />} />}
              >
                <ServiceMenu
                  icon={<AntButton title={logic.t('button.document-history')} type="link" icon={<HistoryOutlined />} />}
                >
                  <ServiceHistory servicdeId={row.subElements[0].serviceId} />
                </ServiceMenu>
                <Secure permissions={[adminRole]}>
                <AntButton
                loading={logic.isUploading}
                  onClick={() => {
                    if (logic.fileInputRef.current) {
                      logic.fileInputRef.current.click();
                    }
                  }}
                  title={logic.t('button.document-upload')}
                  type="link"
                  icon={<FileAddOutlined />}
                />
                <input
                  ref={logic.fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  multiple
                  onChange={(e) => {
                    logic.handleUpload(row.subElements[0].serviceId,e.target.files);
                  }}
                />
                </Secure>
              </ServiceMenu>
            </ServiceMenu>
          );
        return null;
      },
    },
  ];

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.Overview className={`overview ${className ?? ''}`.trim()} style={style}>
        <div className="toolbar">
          <TextInput
            onChange={logic.onFilterChange}
            placeholder={logic.t('searchField.placeholder')}
            className="searchInput"
          />
          <div className="buttonContainer">
          <Secure permissions={[adminRole]}>
            <Button
              onClick={() => {
                logic.handleNavigate(`create`);
              }}
              title={logic.t('button.importServices')}
            />
            </Secure>
          </div>
        </div>
        <div className="tableContainer">
          <CustomDataGrid<DownstreamService & { inUse: boolean; serverAvailable: boolean }>
            data={logic.filteredData ?? []}
            defaultExpandGroups={['groupName']}
            columns={columns2}
            groupNames={['groupName', 'serviceName']}
            loading={logic.isLoading ?? false}
          ></CustomDataGrid>
        </div>
      </styled.Overview>
    </ThemeProvider>
  );
}

export default Overview;
