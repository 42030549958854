import React, { useEffect, useState } from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import translations from './i18n';
import { DetailsProps } from './api-builder';
import { useNavigate, useParams } from '@plasma/ui.application.router';
import { pipelineService } from '../../../utils/pipelineService';
import { agent } from '../../../api/agent';
import { dataHooks } from '../../../hooks/dataHooks';
import { ApiDto } from '../../../models/dtos/ApiDto';
import { useStore } from '../../../stores/store';
import help from './api-builder.help';
import beautify from 'js-beautify';

function useDetailsHook(props: DetailsProps) {
  useResourceBundle(translations, undefined, 'd0f9ee37-4373-4907-93cb-ed4cab88fdec');
  useHelpBundle(help);

  const { t } = useTranslation();
  const { ApiStore, LayoutStore } = useStore();
  const { id } = useParams();
  const [testOpen, setTestOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      LayoutStore.breadcrumbItem = undefined;
      ApiStore.reset();
    };
  }, []);

  const enableSave = Object.entries(ApiStore.basicForm).every(
    (value) =>
      (typeof value[1] !== 'string' ||
      (typeof value[1] === 'string' ? value[1].length > 0 : false) ||
      value[0].includes("requestBody") || value[0].includes("description") || value[0].includes("summary")) &&
      !ApiStore.processors.some(p=>p.error===true)
  );

  const {loading} = dataHooks.useGetInitialData<any>(
    () => {
      if (id)
        return Promise.all([agent.downStreamEndpoint.getAllShort(), agent.upstreamEndpoint.getById(id)])
          .then((response) => {
            LayoutStore.breadcrumbItem = response[1].name;
            ApiStore.endpointServiceList = response[0];
            let pipelineElements = pipelineService.mappingFromPipeline(JSON.stringify((response[1] as any).pipeline));
            ApiStore.pipeline = pipelineElements.pipeline;
            ApiStore.mappedEndpointServices = pipelineElements.mappedEndpoints;
            ApiStore.processors = pipelineElements.processors;
            ApiStore.updateBasicForm({...response[1]})
            ApiStore.initialbasicForm = {...response[1]};
          })
          .catch((e) =>{navigate('/apis', { replace: true })});
      else
        return agent.downStreamEndpoint.getAllShort().then((response) => (ApiStore.endpointServiceList = response));
    },
    false,true
  );

  const { execute: save, loading: isSaveing } = dataHooks.useApiCall(null, 'Api was saved sucessfully !', null);
  const { execute: del, loading: isDeleting } = dataHooks.useApiCall(null, 'Api was deleted sucessfully !', null);

  const deleteHandler = () => {
    del(() =>
      agent.upstreamEndpoint.deleteById(id!).then(() => {
        navigate('/apis', { replace: true });
      }),
    );
  };

  const saveHandler = () => {
    let pipeline = pipelineService.pipelineFromMapping(ApiStore.mappedEndpointServices, ApiStore.processors, ApiStore.pipeline)
    let upstreamEndpoint = new ApiDto(ApiStore.basicForm, pipeline);
    if (props.type === 'create')
      save(() =>
        agent.upstreamEndpoint.save(upstreamEndpoint).then((response) => {
          navigate(`/apis/edit/${response.id}`);
        }),
      );
    else {
      let upstreamEndpoint_ID = upstreamEndpoint as ApiDto & { id: string };
      upstreamEndpoint_ID['id'] = id!;
      save(() => agent.upstreamEndpoint.update(upstreamEndpoint_ID));
    }
  };

  const testOpenHandler = () => {
    setTestOpen(true);
  };

  return {
    id,
    t,
    deleteHandler,
    ApiStore,
    testOpen,
    setTestOpen,
    testOpenHandler,
    saveHandler,
    isSaveing,
    isDeleting,
    enableSave,
    loading,
  };
}

export default useDetailsHook;
