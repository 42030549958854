import { MappedEndpoint } from './mappedEnpoint';

export class PipelineEndpoint {
  index: number;
  type: 'Endpoint';
  downstreamEndpointId: string;
  parameterMapping: { [key: string]: string | null };

  constructor(mappedEndpoint: MappedEndpoint, index: number) {
    this.index = index;
    this.type = 'Endpoint';
    this.downstreamEndpointId = mappedEndpoint.id;
    this.parameterMapping = mappingArrayToObject(mappedEndpoint.mapping ?? []);
  }
}

const mappingArrayToObject = (mapping: { query: string; parameter: string | null }[]) => {
  let output: { [key: string]: string | null } = {};
  mapping.forEach((x) => {
    output[x.query] = x.parameter;
  });
  return output;
};
