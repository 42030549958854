import React, { memo, ReactNode } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Select from '@plasma/ui.input.select/dist/select';
import Button from '@plasma/ui.general.button';
import useToolBarHook from './tool-bar.hook';
import * as styled from './tool-bar.styles';

export interface ToolBarProps {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onDownload: () => void;
  exporting: boolean;
}

type Props = ToolBarProps & ThemeOverride;

function ToolBar(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useToolBarHook();

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ToolBar className={`tool-bar ${className ?? ''}`.trim()} style={style}>
        <Button
          prefixIcon={{ name: 'download' }}
          loading={props.exporting}
          onClick={() => {
            props.onDownload();
          }}
          title={logic.t('button.export')}
        />
      </styled.ToolBar>
    </ThemeProvider>
  );
}

export default ToolBar;
