import React from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useTranslation } from '@plasma/ui.utils.i18n';
import help from './ApiCreateForm.help';
import { ApiCreateFormProps } from './ApiCreateForm';
import { Methods } from '../../../../models/enums/Methods';
import { Protocols } from '../../../../models/enums/Protocols';
import { Api } from '../../../../models/types/api';
import { enumToOptions } from '../../../../utils/enumToOptions';
import { dataHooks } from '../../../../hooks/dataHooks';
import { agent } from '../../../../api/agent';

function useApiCreateFormHook(props: ApiCreateFormProps) {
  useHelpBundle(help);

  const { t } = useTranslation();
  const { data: namespaces } = dataHooks.useGetInitialData<{ value: string; label: string }[]>(
    () =>
      agent.upstreamEndpoint.getNamespaces().then((response) => {
        return response.map((x) => ({ value: x, label: x }));
      }),
    false,
    true,
  );
  const protocols = enumToOptions(Protocols);
  const methods = enumToOptions(Methods);

  const changeHandler = (data: Partial<Api>) => {
    props.store.updateBasicForm(data as Api);
  };

  return {
    t,
    changeHandler,
    protocols,
    methods,
    namespaces,
  };
}

export default useApiCreateFormHook;
