export enum ParameterTypes {
  UUID = 'UUID',
  String = 'String',
  Integer = 'Integer',
  Int32 = 'Int32',
  Int64 = 'Int64',
  Boolean = 'Boolean',
  Number = 'Number',
  DateTime = 'DateTime',
  Date = 'Date',
  Enum = 'Enum',
  Double = 'Double',
  Float = 'Float',
}
