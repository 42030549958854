import React, { Suspense } from 'react';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/
import { Navigate, Route } from '@plasma/ui.application.router';
import LoadingOverlay from '../../../components/core/LoadingOverlay/LoadingOverlay';
import { useTranslation } from '@plasma/ui.utils.i18n';
import SmsSpin from '../../../components/core/SmsSpin/SmsSpin';


const ApiBuilder = React.lazy(() => import('.'));
//@ts-ignore
const adminRole = window.env.ADMIN_ROLE;

function Update() {
  const { t } = useTranslation();
  return (
    <Suspense
      fallback={<LoadingOverlay indicator={<SmsSpin message={t('loading.initial.message')} />} visible={true} />}
    >
      <ApiBuilder type="update" />
    </Suspense>
  );
}
function Create() {
  const { t } = useTranslation();
  return  (
    <Suspense
      fallback={<LoadingOverlay indicator={<SmsSpin message={t('loading.initial.message')} />} visible={true} />}
    >
      <ApiBuilder type="create" />
    </Suspense>
  );
}
export const ApiBuilderRoutes = [
  new Route({
    path: '/apis/edit',
permissions:[adminRole],
    children: [
      new Route({
        path: '',
        element: () => <Navigate to="/apis" replace />,
      }),
      new Route({
        permissions:[adminRole],
        path: '/apis/edit/:id',
        element: Update,
      }),
    ],
  }),
  new Route({
    permissions:[adminRole],
    path: '/apis/create',
    element: Create,
  }),
];
