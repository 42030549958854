import React, { memo, ReactNode, useEffect, useState } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Select from '@plasma/ui.input.select';
import useParameterMapperHook from './ParameterMapper.hook';
import * as styled from './ParameterMapper.styles';
import TextInput from '@plasma/ui.input.text-input';
import Row from '@plasma/ui.layout.row';
import Col from '@plasma/ui.layout.col';
import Button from '@plasma/ui.general.button';
import Icon from '@plasma/ui.general.icon';
import ScrollableArea from '@plasma/ui.layout.scrollable-area';
import Tooltip from '@plasma/ui.display.tooltip';
export type State = {
  query: string;
  parameter: string | null;
};

export interface ParameterMapperProps {
  value?: { query: string; parameter: string | null }[];
  onChange?: (value: State[]) => void;
  className?: string;
  allowAdd?: boolean;
  editableNames?: boolean;
  parameterOptions?: { label: string; value: string }[];
}

type Props = ParameterMapperProps & ThemeOverride;

function ParameterMapper(props: Props) {
  const { className, size, themeMode } = props;

  const logic = useParameterMapperHook(props);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ParameterMapper className={`parameter-mapper ${className ?? ''}`.trim()}>
        <Row gutter={[10, 10]}>
          <ScrollableArea style={{ maxHeight: 150, width: '100%', overflow: 'auto' }}>
            {logic.arrayState?.map((x, ii) => (
              <Col key={ii} style={{ marginBottom: 10 }} span={24}>
                <Row gutter={[10, 10]}>
                  <Col span={8}>
                  <Tooltip visible={props.editableNames?false:undefined} defaultVisible={false}  title={x.query}>
                  <TextInput
                     readOnly={!props.editableNames}
                      onChange={(x) => {
                        logic.changeHandler(ii, x.target.value, 'query');
                      }}
                      defaultValue={x.query}
                    />
                  </Tooltip>

                  </Col>
                  <Col span={16}>
                    <div className="parameterValueContainer">
                      {props.parameterOptions ? (
                        <Select
                          placeholder={logic.t('context-variable')}
                          showSearch
                          allowClear
                          options={props.parameterOptions}
                          className="input"
                          onChange={(x) => {
                            logic.changeHandler(ii, x, 'parameter');
                          }}
                          defaultValue={x.parameter ?? undefined}
                        />
                      ) : (
                        <TextInput
                          className="input"
                          onChange={(x) => {
                            logic.changeHandler(ii, x.target.value, 'parameter');
                          }}
                          defaultValue={x.parameter ?? undefined}
                        />
                      )}
                      {props.allowAdd ? (
                        <Button
                          onClick={() => {
                            logic.removeHandler(ii);
                          }}
                          type="text"
                          icon={<Icon name="remove" />}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            ))}
          </ScrollableArea>
          {props.allowAdd ? (
            <Col span={24}>
              <Row justify="end">
                <Button onClick={logic.addHandler} title="Add" />
              </Row>
            </Col>
          ) : null}
        </Row>
      </styled.ParameterMapper>
    </ThemeProvider>
  );
}

export default ParameterMapper
