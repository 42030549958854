import styled from 'styled-components';

export const ServiceCard = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  user-select: none;
  .badge {
    border-radius: 5px;
    padding: 3px;
    &.GET {
      color: white;
      background-color: #61affe;
    }
    &.POST {
      color: white;
      background-color: #49cc90;
    }
    &.PUT {
      color: white;
      background-color: #fca130;
    }
    &.DELETE {
      color: white;
      background-color: #f93e3e;
    }
  }

  .card {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 2px solid lightgrey;
    .top-bar {
      padding-right: 10px;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .info-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        .error-icon {
          color: red;
        }
      }

      .badge-container {
        height: 100%;
        gap: 10px;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
      }
    }
    .info-container {
      margin-left: 10px;

      .path,.name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .bottom-bar {
      position: absolute;
      bottom: 0px;
      width: 100%;
      gap: 10px;
      padding-right: 10px;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;

      .delete-icon {
        cursor: pointer;
      }
    }
  }
  .selected {
    border: 2px solid #1890ff;
  }
`;
