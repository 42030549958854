import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import * as styled from './CustomDataGrid.styles';
import { Pagination } from 'antd';
import ScrollContainer from '../ScrollContainer/ScrollContainer';

import useCustomTableHook from './CustomDataGrid.hook';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import NoData from '../NoData/NoData';
import SmsSpin from '../SmsSpin/SmsSpin';

export interface ColumnTest<T> {
  minWidth?: number | string;
  width?: number | string;
  dataIndex?: keyof T;
  title: string | JSX.Element;
  key: string;
  render?: (value: any, record: T, index: number) => React.ReactNode;
}
export type datatype<T> = T & { subElements: T[]; key: string; depth: number; isGroup: boolean,groupByValue:any,originalIndex:number,checkDisabled:boolean };
export type ColumnType2<T> = ColumnTest<T & { subElements: T[]; key: string; depth: number; isGroup: boolean,groupByValue:any,originalIndex:number,checkDisabled:boolean }>;

export interface CustomTableProps<T> {
  groupNames?: (keyof T)[];
  className?: string;
  style?: React.CSSProperties;
  data: T[];
  columns: ColumnType2<T>[];
  loading?: boolean;
  pageSize?: number;
  checkable?: boolean;
  checkIdentifier?: keyof T;
  defaultExpandGroups?: (keyof T)[];
  defaultSelected?: string[];
  checkDisabled?: (record?: T) => boolean;
  onCheck?: (elements: T[],ids:string[]) => void;
}

export default function CustomTable<T>(props: CustomTableProps<T>, defaultProps: ThemeOverride) {
  // type datatype = T & { subElements: T[]; key: string; depth: number; isGroup: boolean };
  // type sortElement = { name: keyof T; desc: boolean };

  const { className, style, data, groupNames, loading } = props;
  const { size, themeMode } = defaultProps;
  const logic = useCustomTableHook<T>(props);

  const InfoBody = () => {
    if (loading || (logic.finaldata ?? []).length < 1) {
      return (
        <div style={{ height: `calc(100% - ${logic.height}px)` }} className="info-div">
          {loading ? (
            <LoadingOverlay className="loading-overlay" visible={loading ?? false} indicator={<SmsSpin />} />
          ) : null}
          {!loading && (logic.finaldata ?? []).length < 1 ? <NoData /> : null}
        </div>
      );
    } else return null;
  };
  return (
    <styled.CustomTable className={`custom-table ${className ?? ''}`.trim()} style={style}>
      <ScrollContainer autoHide={false} offsetHeight={logic.height} className="scrollContainer">
        <table ref={logic.tableRef} className="tabletest">
          <thead>
            <tr className="headerRow">{logic.columns2?.map((x, ii) => x.title(ii))}</tr>
          </thead>
          <tbody>
            {logic.finaldata?.map((x, ii) => (
              <tr key={ii} className="dataRow">
                {logic.columns2?.map((y, iii) => (
                  <td key={iii}>
                    {y.render ? y.render(x[y.dataIndex as keyof typeof x], x, ii) : x[y.dataIndex as keyof typeof x]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollContainer>
      {props.pageSize ? (
        <div className="footer">
          <Pagination
            responsive
            defaultCurrent={1}
            onChange={(x) => {
              logic.setPage(x);
            }}
            current={logic.page}
            pageSize={props.pageSize}
            total={props.data.length}
            showLessItems
            showSizeChanger={false}
          />
        </div>
      ) : null}
      <InfoBody />
    </styled.CustomTable>
  );
}
