import React, { useEffect, useMemo, useState } from 'react';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import translations from './i18n';
import { RequestBodyCreatorProps } from './requestBody-creator';
import { requestBody } from '../../../models/types/requestBody';
function useRequestBodyCreatorHook(props: RequestBodyCreatorProps) {
  useResourceBundle(translations, undefined, '9d3a718e-15e2-4a94-a372-7056b8221bcf');

  const { t } = useTranslation();

  const [internalParams, setInternalParams] = useState<requestBody>({ required: false, schema: '', description: '',type:'' });
  const [descrModal, setDescrModal] = useState(false);
  const [tempDescr, setTempDescr] = useState<string>('');
  const params  = props.value ?? internalParams;

  const setState = (state: requestBody) => {
    if (props.onChange) {
      props.onChange(state);
    }
    if (!props.value) {
      setInternalParams(state);
    }
  };

  const changeHandler = () => {
    setState(params);
  };

  const saveHandler = (required?: boolean, schema?: string, description?: string, type?: string) => {
    const _params = { ...params };
    if (required != undefined) _params.required = required;
    if (schema != undefined) _params.schema = schema;
    if (type != undefined) _params.type = type;
    if (description != undefined) _params.description = description;
    setState(_params);
  };
  const descrSaveHandler = () => {
    saveHandler(undefined, undefined, tempDescr);
    setDescrModal(false);
  };

  const tempDescrChangeHandler = (value: string) => {
    setTempDescr(value);
  };

  const openDescrModal = () => {
    setTempDescr(params.description);
    setDescrModal(true);
  };

  const closeDescrModal = () => {
    setDescrModal(false);
  };
  return {
    saveHandler,
    t,
    changeHandler,
    tempDescrChangeHandler,
    descrModal,
    openDescrModal,
    closeDescrModal,
    descrSaveHandler,
    tempDescr,
    params
  };
}

export default useRequestBodyCreatorHook;
