import React, { ReactNode } from 'react';
import ThemeProvider, { SizeType } from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import * as styled from './service-builder.styles';
import themes from './themes';
import ScrollableArea from '@plasma/ui.layout.scrollable-area';
import Modal from '@plasma/ui.layout.modal';
import SourceForm from '../../../components/features/service-builder/SourceForm';
import EndpointSelector from '../../../components/features/service-builder/EndpointSelector';
import Confirmation from '../../../components/features/service-builder/Confirmation';
import LoadingOverlay from '../../../components/core/LoadingOverlay/LoadingOverlay';

import Button from '@plasma/ui.general.button';
import Tooltip from '@plasma/ui.display.tooltip';
import { Steps } from 'antd';
import Icon from '@plasma/ui.general.icon/dist/icon';
import SmsSpin from '../../../components/core/SmsSpin/SmsSpin';
import useServiceBuilderHook2 from './service-builder.hook';
import { urlChecker } from '../../../utils/urlChecker';

export interface ServiceBuilderProps {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  type: 'create' | 'update';
}

type Props = ServiceBuilderProps & ThemeOverride;

function ServiceBuilder(props: Props) {
  const { children, className, style, size, themeMode } = props;
  const { Step } = Steps;

  const logic = useServiceBuilderHook2(props);
  const tabs = [
    {
      title: logic.t('serviceForm.title'),
      element: (
        <SourceForm
        additionalUrlCount={logic.serviceSource?.additionalUrls.length??0}
          isUrlInvalid={(logic.serviceSource?.url?.length ?? 0) > 0 && !urlChecker(logic.serviceSource?.url??"")}
          initialValues={logic.initialData}
          setServiceType={logic.setServiceType}
          serviceType={logic.serviceType}
          onChange={logic.onServiceSourceChange}
          documentHistory={logic.initialDataSource?.documentations ?? []}
          existingGroups={logic.groupOptions??[]}
  />
      ),
    },
    {
      title: `${logic.t('serviceSelector.title')}`,
      header:`${logic.t('serviceSelector.head')}: ${logic.apiInfos?.title} (v.${logic.apiInfos?.version})`,
      element: (
        <EndpointSelector
          includesUsedServices={logic.includesUsedServices}
          data={logic.services ?? []}
          setServices={logic.setServices}
          preSelected={logic.preSelected??[]}
        />
      ),
    },
    {
      title: logic.t('confirmation.title'),
      element: <Confirmation importDone={logic.dataImported} selectedServices={logic.selectedServices ?? []} />,
    },
  ];
  const handleModalConfirm = () => {
    Modal.confirm({
      title: logic.t('delete.confirm.title'),
      icon: <Icon name="test" />,
      content: logic.t('delete.confirm.message'),
      sizeTheme: size,
      onOk() {
        logic.deleteHandler();
      },
      centered: true,
      okType: 'danger',
      visible: true,
    });
  };

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <LoadingOverlay
        visible={logic.loadServices}
        indicator={<SmsSpin message={logic.t('loading.extraction.message')} />}
      />
      <styled.ServiceBuilder className={`service-creator ${className ?? ''}`.trim()} style={style}>
        {logic.render ? (
          <>
            <div className="stepContainer">
              <Steps current={logic.step}>
                {tabs.map((tab, ii) => (
                  <Step key={ii} title={tab.title} />
                ))}
              </Steps>
            </div>
            <div className="titleContainer">
              <h1>{tabs.at(logic.step)?.header??tabs.at(logic.step)?.title}</h1>
              <div className="button-container">
                {props.type === 'update' ? (
                  <>
                    {logic.step === 0 ? (
                      <Button
                        loading={logic.isSaving}
                        onClick={logic.quickSaveHandler}
                        disabled={logic.isDeleting || !logic.enableUpdate}
                        title="Save"
                      ></Button>
                    ) : null}
                    <Tooltip title="prompt text">
                      <Button
                        onClick={() => handleModalConfirm()}
                        loading={logic.isDeleting}
                        type="danger"
                        disabled={logic.itemsInUse > 0}
                        title={logic.t('button.delete')}
                      />
                    </Tooltip>
                  </>
                ) : null}
                {logic.step === 2 ? (
                  <Button
                    loading={logic.saveServices}
                    title={logic.t('button.import')}
                    disabled={logic.dataImported}
                    onClick={logic.importHandler}
                  />
                ) : (
                  <>
                    <Button
                      disabled={logic.step === 0}
                      title={logic.t('button.back')}
                      onClick={logic.stepBackHandler}
                    />
                    <Button
                      disabled={
                        (!logic.step1Done && logic.step === 0) ||
                        ((!logic.step2Done || logic.includesUsedServices === false) && logic.step === 1)
                      }
                      title={logic.t('button.next')}
                      onClick={logic.nextStepHandler}
                    />
                  </>
                )}
              </div>
            </div>
            <ScrollableArea className="contentContainer">{tabs.at(logic.step)?.element}</ScrollableArea>
          </>
        ) : null}
      </styled.ServiceBuilder>
    </ThemeProvider>
  );
}

export default ServiceBuilder;
