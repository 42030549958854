import React, { memo } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useApiCreateFormHook from './ApiCreateForm.hook';
import * as styled from './ApiCreateForm.styles';
import Col from '@plasma/ui.layout.col';
import FormField from '@plasma/ui.input.form-field';
import Row from '@plasma/ui.layout.row';
import TextInput from '@plasma/ui.input.text-input';
import TextArea from '@plasma/ui.input.text-area';
import Switch from '@plasma/ui.input.switch';
import Select from '@plasma/ui.input.select/dist/select';
import AutoComplete from '@plasma/ui.input.auto-complete';
import Form from '@plasma/ui.input.form';

import { observer } from 'mobx-react-lite';
import ApiStore from '../../../../stores/ApiStore';
import ParameterTable from '../../../shared/parameter-table';
import ParameterCreator from '../../../shared/parameter-creator';
import { requestBodyToSourceCode } from '../../../../utils/requestBodyToSourceCode';
import ResponseCreator from '../../../shared/response-creator';
import RequestBodyCreator from '../../../shared/requestBody-creator';



export interface ApiCreateFormProps {
  className?: string;
  store: ApiStore;
}

type Props = ApiCreateFormProps & ThemeOverride;

function ApiCreateForm(props: Props) {
  const { className, size, themeMode } = props;

  const logic = useApiCreateFormHook(props);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ApiCreateForm className={`api-create-form ${className ?? ''}`.trim()}>
        <Form initialValues={props.store.initialbasicForm} onChange={logic.changeHandler} id="apiform" className="form">
          <Row>
            <Col span={8}>
              <FormField required label={logic.t('basic.apiCreateForm.namespace')} name="namespace">
                <AutoComplete options={logic.namespaces} />
              </FormField>
            </Col>
            <Col span={8}>
              <FormField required label={logic.t('basic.apiCreateForm.tag')} name="tag">
                <TextInput spellCheck={false} kind="outlined" />
              </FormField>
            </Col>
            <Col span={8}>
              <FormField required label={logic.t('basic.apiCreateForm.name')} name="name">
                <TextInput spellCheck={false} kind="outlined" />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormField required label={logic.t('basic.apiCreateForm.summary')} name="summary">
                <TextArea className="summary-field" spellCheck={false} />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormField required label={logic.t('basic.apiCreateForm.description')} name="description">
                <TextArea className="description-field" spellCheck={false} />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormField required label={logic.t('basic.apiCreateForm.status')} valuePropName="checked" name="status">
                <Switch label={logic.t('basic.apiCreateForm.active')} />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormField required label={logic.t('basic.apiCreateForm.protocol')} name="protocol">
                <Select options={logic.protocols} />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormField required label={logic.t('basic.apiCreateForm.method')} name="method">
                <Select options={logic.methods} />
              </FormField>
            </Col>
            <Col span={16}>
              <FormField required label={logic.t('basic.apiCreateForm.path')} name="path">
                <TextInput autoCorrect={undefined} kind="outlined" />
              </FormField>
            </Col>
          </Row>
          <Row className="lastRow">
            <Col span={24}>
              <FormField required label={logic.t('basic.apiCreateForm.parameters')} name="requestParameters">
                <ParameterCreator className="parameter-creator" />
              </FormField>
            </Col>
          </Row>
          <Row >
            <Col span={24}>
              <FormField required label={logic.t('basic.apiCreateForm.requestBody')} name="requestBody">
               <RequestBodyCreator className='request-body-creator'/>
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col >
              <FormField required valuePropName="checked" name="validateBody">
                <Switch label={logic.t('basic.apiCreateForm.validate-body')} />
              </FormField>
            </Col>
          </Row>
          <Row className="lastRow">
            <Col span={24}>
              <FormField required label={logic.t('basic.apiCreateForm.responses')} name="responses">
                <ResponseCreator />
              </FormField>
            </Col>
          </Row>
        </Form>
      </styled.ApiCreateForm>
    </ThemeProvider>
  );
}

export default observer(ApiCreateForm) as unknown as typeof ApiCreateForm;
