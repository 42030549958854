import styled from 'styled-components';

export const ApiTestForm = styled.div`
  width: 100%;
  height: 100%;
  .plasma-text-area {
    resize: none;
    min-height: 200px;
  }
`;
