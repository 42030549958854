import React from 'react';
import { useRef } from 'react';
import Button from '@plasma/ui.general.button';
export interface DirectUploadProps {
  handleUpload: (file: Blob) => void;
  title?: string;
  loading?: boolean;
}
export default function DirectUpload(props: DirectUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) props.handleUpload(e.target.files[0]);
    fileInputRef.current && (fileInputRef.current.value = '');
  };
  return (
    <>
      <input ref={fileInputRef} type="file" style={{ display: 'none' }} multiple onChange={onUpload} />
      <Button
        loading={props.loading}
        title={props.title ?? 'Upload'}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
      />
    </>
  );
}
