import styled from 'styled-components';

export const RequestBodyCreator = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;

  .plasma-text-area {
    resize: none;
  }

  .body-field {
    flex-grow: 1;
  }
  .ant-input {
    border-radius: 0;
  }
  .container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .type-field {
    width: 200px;
  }
`;
