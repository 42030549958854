import React from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import translations from './i18n';
import help from './EndpointInfo.help';

function useEndpointInfoHook() {
  useResourceBundle(translations, undefined, '83bb951a-4135-4941-b29d-1d873e1060a0');
  useHelpBundle(help);

  const { t } = useTranslation();

  return {
    t,
  };
}

export default useEndpointInfoHook;
