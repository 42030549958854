import FormField from '@plasma/ui.input.form-field';
import Select from '@plasma/ui.input.select/dist/select';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@plasma/ui.general.button';
import TextInput from '@plasma/ui.input.text-input';
import { SwaggerSource } from '../../../../../models/enums/SwaggerSource';
import Col from '@plasma/ui.layout.col';
import FileUpload from '../../../../shared/FileUpload';
import { useTranslation } from '@plasma/ui.utils.i18n';

export interface SwaggerFormProps {
  initialValue?: SwaggerSource;
  swaggerSources: {
    value: string;
    label: string;
  }[];
  documentHistory: { id: string; version: string; title: string, inUse:boolean }[];
}

export default function SwaggerForm(props: SwaggerFormProps) {
  const [source, setSource] = useState(props.initialValue ?? SwaggerSource.Local);
  const { t } = useTranslation();
  return (
    <>
      <Col span={2}>
        <FormField className="select-field" required label={t('serviceForm.swaggerSource')} name="swaggerSource">
          <Select onSelect={setSource} options={props.swaggerSources} />
        </FormField>
      </Col>

      {source === SwaggerSource.Local ? (
        <Col span={3}>
          <FormField className="select-field" required label={t('serviceForm.swaggerFile')} name="swaggerFile">
            <FileUpload  maxCount={1} documentHistory={props.documentHistory} />
          </FormField>
        </Col>
      ) : (
        <Col span={8}>
          <FormField required label={t('serviceForm.swaggerPath')} name="swaggerPath">
            <TextInput kind="outlined" />
          </FormField>
        </Col>
      )}
    </>
  );
}
