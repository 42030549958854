import { Help } from '@plasma/ui.application.app';

const help: Help[] = [
  // {
  //   title: 'Details',
  //   description: 'description of Details',
  //   url: '/details',
  // },
];

export default help;
