import React, { useState } from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import translations from './i18n';
import { FileUploadProps } from './FileUpload';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

function useFileUploadHook(props: FileUploadProps) {
  useResourceBundle(translations, undefined, 'eecb52d1-6c17-4028-aeed-ad876cbd2889');

  const { t } = useTranslation();

  const [fileList, setFileList] = useState<UploadFile<any>[]>(props.value ?? []);
  const changeHandler = (value: UploadChangeParam<UploadFile<any>>) => {
        setFileList([...value.fileList]);
    if (props.onChange) props.onChange([...value.fileList]);
  };
  const historyChangeHandler = (value:UploadFile<any>[]) => {
    setFileList(value);
if (props.onChange) props.onChange(value);
};
  return {
    t,
    changeHandler,
    fileList,
    historyChangeHandler
  };
}

export default useFileUploadHook;
