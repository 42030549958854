import styled from 'styled-components';

export const EndpointInfo = styled.div`
  width: 100%;
  height: 100%;
  .ant-form-item-label {
    padding: 0px;
  }
  .form-body {
    gap: 10px !important;
    .ant-row.plasma-row {
      margin: 0;
    }
    .ant-col {
      padding-left: 0px !important;
    }
  }
  .plasma-text-area {
    resize: none;
  }
  .parent {
    display: flex;
    gap: 5px;
    align-items: center;
    span {
      user-select: none;
    }
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
  }
  .disabled {
    pointer-events: none;
    color: lightgray;
    .icon {
      color: lightgray;
    }
  }
`;
