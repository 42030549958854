import baseStyled, { ThemedCssFunction, ThemedStyledInterface, css as baseCss } from 'styled-components';

import { OverviewTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<OverviewTheme>;
const css = baseCss as ThemedCssFunction<OverviewTheme>;

export const Overview = styled.div`
  width: 100%;
  height: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  min-height: 500px;

  .action-container {
    justify-content: space-between;
    display: flex;
    padding-right: 10px;
    height: 20px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    button {
      padding: 0;
    }
  }
  .mainbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .searchInput {
      width: 200px;
    }
    .toolBar {
      margin-left: auto;
    }
  }

  .statusButton {
    margin-left: 10px;
  }

  .tableContainer {
    flex-grow: 1;
    min-height: 0px;

    .ant-spin {
      height: 100% !important;
    }

    [role='table']:first-child {
      height: 100% !important;
      background-color: #ffffff;

      > :not([role]) {
        height: 100%;
      }

      > :first-child {
        > [role='rowgroup'] {
          height: calc(100% - 40px) !important;
        }
      }
    }
  }
`;
