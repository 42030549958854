import styled from 'styled-components';

export const CustomTable = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .scrollContainer {
    border: 1px solid lightgrey;
    position: relative;
    width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }
  .info-div {
    position: absolute;
    bottom: 0;
    width: 100%;

    pointer-events: none;
  }
  .footer {
    min-height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 5px;
    background-color: #f4f4f4;
    border: 1px solid lightgrey;
    border-top: 0;
  }
  .loading-overlay {
  }

  .tabletest {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    .headerRow {
      position: sticky;
      top: 0px;
      z-index: 1;

      border-bottom: 1px solid #c6c6c6;
      th {
        border-bottom: 1px solid #c6c6c6;
      }
      .columContainer {
        padding: 7px 7px;
        padding-right: 0px;
        background-color: #f4f4f4;
        .columnContent {
          font-weight: 600;
          color: #2b2b2b;
          display: flex;
          align-items: center;
          text-align: center;
          gap: 5px;
          //       justify-content: space-between;
          line-height: 14px;
          .title-span {
            text-align: left;
          }
          .hide {
            color: transparent;
          }
          .sortWrapper {
            cursor: pointer;
            display: flex;
          }
          :hover {
            .hide {
              color: black;
            }
          }
        }
      }

      .columContainer:not(:last-child) {
        .columnContent {
          border-right: 1px solid #c6c6c6;
        }
      }
    }

    .dataRow {
      border-bottom: 1px solid #c6c6c6;
      td {
        padding: 7px 7px;
        padding-right: 0px;
        line-height: 19px;
        height: 35px;
        border-bottom: 1px solid #c6c6c6;
      }
      .group-cell {
        user-select: none;
        cursor: pointer;
        display: flex;
        gap: 5px;

        flex-direction: row;
        align-items: center;
        span {
          height: 20px;
        }
      }
    }
    .dataRow:hover {
      background-color: #a6c8ff !important;
    }
  }
`;
