import { DownstreamRequestParameter } from './downstreamEndpoint';
import { endpointParameter } from './endpointParameter';
import { requestBody } from './requestBody';
import { response } from './response';

export class Api {
  description: string;
  summary: string;
  method: string;
  path: string;
  protocol: string;
  status: boolean;
  namespace: string;
  tag: string;
  name: string;
  validateBody: boolean;
  requestParameters: endpointParameter[];
  responses: response[];
  requestBody:requestBody

  constructor(
    description: string = '',
    summary: string = '',
    method: string = '',
    path: string = '',
    protocol: string = '',
    validateBody: boolean = false,
    status: boolean = false,
    namespace: string = '',
    name: string = '',
    tag: string = '',
    requestParameter: endpointParameter[] = [],
    responses: response[] = [],
    requestBody: requestBody = { required: false, schema: '', description: '' }
  ) {
    this.description = description;
    this.summary = summary;
    this.method = method;
    this.path = path;
    this.protocol = protocol;
    this.validateBody = validateBody;
    this.status = status;
    this.namespace = namespace;
    this.name = name;
    this.tag = tag;
    this.requestParameters = requestParameter;
    this.responses = responses;
    this.requestBody = requestBody
  }
}
