import baseStyled, { ThemedCssFunction, ThemedStyledInterface, css as baseCss } from 'styled-components';

import { ResponseCreatorTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<ResponseCreatorTheme>;
const css = baseCss as ThemedCssFunction<ResponseCreatorTheme>;

export const ResponseCreator = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;

  .listContainer {
    overflow: hidden;
    border: 1px solid lightgray;
    background-color: white;
    flex-grow: 1;
    position: relative;
    padding-right: 0 !important;
    .scrollContainer {
      width: 100%;
      height: 100%;
      .os-scrollbar-vertical {
        height: calc(100% - 55px);
        margin-top: 55px;
      }
    }
    .no-data-overlay {
      position: absolute;
      top: 55px;
      width: 100%;
      background-color: white;
      height: calc(100% - 55px) !important;
    }

    .ant-table-wrapper {
      width: 100%;
      .ant-spin-nested-loading {
        height: 100%;
        .ant-spin-container {
          height: 100%;
          .ant-table {
            //  background-color: transparent;
          }
          .ant-table-empty {
            height: 100%;
            .ant-table-container {
              height: 100%;
              .ant-table-content {
                height: 100%;
                table {
                  height: 100%;
                }
                .ant-form-item-control {
                  .ant-form-item-control-input {
                    height: 100%;
                    .ant-form-item-control-input-content {
                      height: 100%;
                      > :first-child {
                        height: 100%;
                        > .ant-input {
                          height: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-table-thead {
      position: sticky;
      z-index: 1;
      top: 0;
    }
    .ant-table-thead > tr > th {
      white-space: nowrap;
    }

    .ant-table-tbody {
      position: relative;
      height: 100%;
      padding: 0 !important;
    }
    .ant-table-tbody > tr > td {
      padding: 5px !important;
    }

    .list {
      .plasma-list-rows-container {
        height: 100% !important;
      }
      height: 100%;
    }
    .listElementContainer {
      display: flex;
      margin-bottom: 10px;
      flex-direction: row;
      gap: 10px;
    }

    .headerContainer {
      display: flex;
      border: 1px solid gray;
      flex-direction: row;
      gap: 10px;
    }
  }
  .codeField {
    min-width: 80px;
    flex-grow: 2;
  }

  .addButton {
    flex-basis: 0;
    margin-left: auto;
  }
  .action-bar {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;
