import React, { useEffect, useMemo, useState } from 'react';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import { dataHooks } from '../../../hooks/dataHooks';
import { agent } from '../../../api/agent';
import { useNavigate, useParams } from '@plasma/ui.application.router';
import { ServiceBuilderProps } from './service-builder';
import translations from './i18n';
import { useStore } from '../../../stores/store';
import useConfigurationStep from './useConfigurationStep';
import useSelectionStep from './useSelectionStep';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import useConfirmationStep from './useConfirmationStep';

function useServiceBuilderHook2(props: ServiceBuilderProps) {
  const { t } = useTranslation();
  useResourceBundle(translations, undefined, '4f422342-178a-4b6d-80d9-2e8f74a83565');
  const { id } = useParams();
  const { LayoutStore } = useStore();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [services, setServices] = useState<
  (DownstreamService & {
    selected: boolean;
    inUse: boolean;
  })[]
>();

const {
  isSaving,
  serviceSource,
  enableUpdate,
  firstStepHandler,
  apiInfos,
  loadServices,
  serviceType,
  setServiceType,
  onServiceSourceChange,
  quickSaveHandler,
  step1Done,
  initialDataSource,
    initialData
} = useConfigurationStep(id, props.type, setStep, setServices);

const {secondStepHandler, preSelected, selectedServices, step2Done, includesUsedServices } = useSelectionStep(
  initialDataSource,
  props.type,
  serviceSource!,
  setStep,
  services ?? [],
);


const { saveServices, dataImported, importHandler } = useConfirmationStep(props.type, serviceSource!, apiInfos,selectedServices);

// Action if component gets unmounted
useEffect(() => {
  return () => {
    LayoutStore.breadcrumbItem = undefined;
  };
}, []);



  // Names of existing groups
  const { data: groupOptions } = dataHooks.useGetInitialData(
    () => agent.endpointGroup.getGroupNames().then((reponse) => reponse.map((g) => ({ label: g, value: g }))),
    true,
    true,
  );

  // Functionality for deleting an endpoint group
  const { execute, loading: isDeleting } = dataHooks.useApiCall(
    () =>
      agent.endpointGroup.delete(id!).then(() => {
        navigate('/services', { replace: true });
      }),
    t('notification.endpoint.delete.success'),
    null,
  );

  // Handler for moving to the next step
  const nextStepHandler = () => {
    switch (step) {
      case 0:
        firstStepHandler();
        break;
      case 1:
        secondStepHandler();
        break;
    }
  };

  // Handler to move one step back
  const stepBackHandler = () => {
    setStep(step - 1);
  };

  // Delete handler
  const deleteHandler = () => {
    execute();
  };

  // Marker if the page should already be rendered if the process is not an update or initialData is already loaded
  const render = initialDataSource || props.type !== 'update';

  // Amount of downstream services that are in use
  const itemsInUse = useMemo(() => {
    return initialDataSource?.downstreamEndpoints.filter((endpoints) => endpoints.inUse).length ?? 0;
  }, [initialDataSource]);




  return {
    isSaving,
    quickSaveHandler,
    step1Done,step2Done,includesUsedServices,
    t,
    step,
    nextStepHandler,
    deleteHandler,
    isDeleting,
    stepBackHandler,
    render,
    itemsInUse,
    groupOptions,
    saveServices,
    dataImported,
    importHandler,
    enableUpdate,
    loadServices,
    preSelected,
    setServices,
    services,apiInfos,
    initialDataSource,
    onServiceSourceChange,
    serviceType,
    setServiceType,
    serviceSource,
    initialData,
    selectedServices

  };
}

export default useServiceBuilderHook2;
