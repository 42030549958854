import { ProcessorService } from './ProcessorService';

export class PipelineProcessor {
  index: number;
  type = 'Processor';
  implementation: string;
  name: string;

  constructor(processor: ProcessorService, index: number) {
    this.index = index;
    this.implementation = processor.sourceCode;
    this.name = processor.name;
  }
}
