import React from 'react';
import Tabs from '@plasma/ui.navigation.tabs';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Col from '@plasma/ui.layout.col';
import FormField from '@plasma/ui.input.form-field';
import Row from '@plasma/ui.layout.row';
import TextInput from '@plasma/ui.input.text-input';
import useProcessorFormHook from './ProcessorForm.hook';
import * as styled from './ProcessorForm.styles';
import Form from '@plasma/ui.input.form';
import Select from '@plasma/ui.input.select/dist/select';
import { CodeEditor } from '../../../shared/CodeEditor';
import { ProcessorService } from '../../../../models/types/ProcessorService';
import { PipelineElement } from '../../../../models/enums/PipelineElement';
import { ProcessorType } from '../../../../models/enums/ProcessorType';
import { CopyBlock } from 'react-code-blocks';
export interface ProcessorFormProps {
  className?: string;
  style?: React.CSSProperties;
  processor: ProcessorService;
  isListElement: boolean;
  updateProcessor: (newObject: {
    name: string;
    sourceCode: string;
    error: boolean;
    type: PipelineElement;
    method: ProcessorType;
    id: string;
  }) => void;
}

type Props = ProcessorFormProps & ThemeOverride;

function ProcessorForm(props: Props) {
  const { className, style, size, themeMode } = props;

  const logic = useProcessorFormHook(props);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ProcessorForm className={`ProcessorForm ${className ?? ''}`.trim()} style={style}>
        <Tabs type="line">
        {!props.isListElement ? (
          <Tabs.TabPane tab={logic.t('mapping.processorForm.tab.settings')} key="1">
            <div className="content-container">
              <Form
                notifications={{ disabled: true }}
                id="processorForm"
                initialValues={props.processor}
                className="form"
                onChange={logic.formSaveHandler}
              >
                <Row>
                  <Col span={24}>
                    <FormField required label={logic.t('mapping.processorForm.settings.name')} name="name">
                      <TextInput spellCheck={false} kind="outlined" />
                    </FormField>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormField required label={logic.t('mapping.processorForm.settings.type')} name="method">
                      <Select className="type-select" options={logic.typeOptions} />
                    </FormField>
                  </Col>
                </Row>
              </Form>
              <CodeEditor
                value={props.processor.sourceCode}
                title={logic.t('mapping.processorForm.settings.sourceCode')}
                className="editor"
                onValidate={(x) => {
                  logic.codeErrorParser(x);
                }}
                onChange={(x) => {
                  logic.codeSaveHandler(x);
                }}
              />
            </div>
          </Tabs.TabPane>
        ):null}
           <Tabs.TabPane tab={logic.t('mapping.processorForm.tab.help')} key="2">
           <div className="content-container">
           <p>{logic.t('processor.help.preface')}</p>
           <p className='help-header'><b>{logic.t('processor.help.input.header')}</b></p>
           <p>{logic.t('processor.help.input.text')}</p>
           <p className='help-header'><b>{logic.t('processor.help.nextBody.header')}</b></p>
           <p>{logic.t('processor.help.nextBody.text')}</p>
           <p className='help-header'><b>{logic.t('processor.help.context.header')}</b></p>
           <p>{logic.t('processor.help.context.text')}</p>
           <p className='help-header'><b>{logic.t('processor.help.httpResponse.header')}</b></p>
           <p>{logic.t('processor.help.httpResponse.text')}</p>
           <p className='help-header'><b>{logic.t('processor.help.example.header')}</b></p>
           <CopyBlock
      text={logic.exampleCode}
      language="javascript"
      showLineNumbers={true}

    />
           </div>
         </Tabs.TabPane>
        </Tabs>
      </styled.ProcessorForm>
    </ThemeProvider>
  );
}

export default ProcessorForm;
