import React, { memo, ReactNode } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Select from '@plasma/ui.input.select/dist/select';
import Button from '@plasma/ui.general.button';
import useToolBarHook from './tool-bar.hook';
import * as styled from './tool-bar.styles';
import FileUpload from '../../../shared/FileUpload';
import DirectUpload from '../../../shared/DirectUpload/DirectUpload';
import Secure from '@plasma/ui.application.secure';

export interface ToolBarProps {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onSpanChange: (filter: any) => void;
  handleNavigate: (path: string) => void;
  rangeValues: {
    value: number;
    label: string;
  }[];
}

type Props = ToolBarProps & ThemeOverride;

function ToolBar(props: Props) {
  const { children, className, style, size, themeMode } = props;

  //@ts-ignore
  const adminRole = window.env.ADMIN_ROLE;

  const logic = useToolBarHook();

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ToolBar className={`tool-bar ${className ?? ''}`.trim()} style={style}>
        <Secure permissions={[adminRole]}>
          <Button
            onClick={() => {
              props.handleNavigate(`create`);
            }}
            title={logic.t('button.createApi')}
          />
          <Button
            onClick={() => {
              props.handleNavigate(`create-from-services`);
            }}
            title={logic.t('button.createFromServices')}
          />
          <DirectUpload loading={logic.loading} title={logic.t('button.import')} handleUpload={logic.handleUpload} />
        </Secure>
        <Button
          onClick={() => {
            props.handleNavigate(`export`);
          }}
          title={logic.t('button.export')}
        />
      </styled.ToolBar>
    </ThemeProvider>
  );
}

export default ToolBar;
