import React from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useServiceFormHook from './ServiceForm.hook';
import * as styled from './ServiceForm.styles';
import Tabs from '@plasma/ui.navigation.tabs';
import { observer } from 'mobx-react-lite';
import { MappedEndpoint } from '../../../../models/types/mappedEnpoint';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';
import ParameterMapper from '../../../shared/ParameterMapper';
import EndpointInfo from '../../../shared/EndpointInfo';
import { State } from '../../../shared/ParameterMapper/ParameterMapper';

import SmsSpin from '../../../core/SmsSpin/SmsSpin';

export interface ServiceFormProps {
  className?: string;
  style?: React.CSSProperties;
  endpoint: DownstreamService;
  mappedEndpoint: MappedEndpoint;
  isListElement: boolean;
  context: {
    label: string;
    value: string;
  }[];
  updateMappedService: (id: string, mapping: State[]) => void;
}
export type DataType = {
  name: string;
  value: string | null;
};
type Props = ServiceFormProps & ThemeOverride;

function ServiceForm(props: Props) {
  const { className, style, size, themeMode } = props;

  const logic = useServiceFormHook(props);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ServiceForm className={`ServiceForm ${className ?? ''}`.trim()} style={style}>
        <Tabs type="line">
          <Tabs.TabPane tab={logic.t('mapping.serviceForm.tab.information')} key="1">
            {logic.loading?<SmsSpin/>:<EndpointInfo enpoint={logic.endpoint} />}
          </Tabs.TabPane>
          {!props.isListElement ? (
            <Tabs.TabPane
              disabled={logic.paramMapping?.length < 1}
              tab={logic.t('mapping.serviceForm.tab.parameterMapping')}
              key="2"
            >
              <div className="tableContainer">
                <ParameterMapper
                  onChange={logic.changeHandler}
                  value={logic.paramMapping}
                  parameterOptions={props.context}
                />
              </div>
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </styled.ServiceForm>
    </ThemeProvider>
  );
}

export default observer(ServiceForm) as unknown as typeof ServiceForm;
