const fileLoader = async (response: any) => {
  try {

    const contentDisposition = response.headers['content-disposition'];
    const filenameMatch = contentDisposition.match(/filename="(.*?)"/);
    const filename = filenameMatch ? filenameMatch[1] : 'downloaded.json';

    // Create a URL object from the Blob
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.style.display = 'none';

    // Set the href and download attributes on the anchor
    a.href = url;
    a.setAttribute('download', filename);

    // Simulate a click event to trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up the temporary anchor and URL object
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export { fileLoader };
