import React, { ReactNode } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useRequestBodyCreatorHook from './requestBody-creator.hook';
import * as styled from './requestBody-creator.styles';
import themes from './themes';
import 'overlayscrollbars/overlayscrollbars.css';
import { Input } from 'antd';
import Button from '@plasma/ui.general.button';
import Modal from '@plasma/ui.layout.modal';
import Switch from '@plasma/ui.input.switch';
import { requestBody } from '../../../models/types/requestBody';
import { AutoComplete } from 'antd';
import { contentTypes } from '../constants';
const { TextArea } = Input;

export interface RequestBodyCreatorProps {
  className?: string;
  style?: React.CSSProperties;
  onChange?: (data: requestBody) => void;
  value?: requestBody;
}

type Props = RequestBodyCreatorProps & ThemeOverride;

function RequestBodyCreator(props: Props) {
  const { className, style, size, themeMode } = props;

  const logic = useRequestBodyCreatorHook(props);

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.RequestBodyCreator className={`parameter-creator ${className ?? ''}`.trim()} style={style}>
      <AutoComplete
      placeholder={"Content type"}
        value={logic.params.type}
        className="type-field"
        options={contentTypes}
        onChange={(value) => {
          logic.saveHandler(undefined,undefined,undefined,value);
        }}
      />
        <TextArea
          value={logic.params.schema}
          onChange={(e) => logic.saveHandler(undefined, e.target.value, undefined, undefined)}
          className="body-field"
          spellCheck={false}
        />
        <div className="container">
          <Button
            onClick={() => {
              logic.openDescrModal();
            }}
            type={logic.params.description?.length > 0 ? 'primary' : 'secondary'}
            title="Description"
          />
          <Switch
            checked={logic.params.required}
            onChange={(e) => logic.saveHandler(e.valueOf(), undefined, undefined,undefined)}
            label={logic.t('basic.apiCreateForm.required')}
          />
        </div>
      </styled.RequestBodyCreator>
      <Modal
        visible={logic.descrModal}
        title="Description"
        okText="Save"
        cancelText="Cancel"
        onCancel={logic.closeDescrModal}
        onOk={logic.descrSaveHandler}
      >
        <Input
          value={logic.tempDescr}
          onChange={(e) => logic.tempDescrChangeHandler(e.target.value ?? '')}
          spellCheck={false}
        />
      </Modal>
    </ThemeProvider>
  );
}

export default RequestBodyCreator;
