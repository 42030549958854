import { ApiDto } from '../models/dtos/ApiDto';
import { ApiParameterArea } from '../models/enums/ApiParameterArea';
import { ParameterTypes } from '../models/enums/ParameterTypes';
import { PipelineElement } from '../models/enums/PipelineElement';
import { Api } from '../models/types/api';
import {
  DownstreamRequestParameter,
  DownstreamService,
  DownstreamServiceItem,
} from '../models/types/downstreamEndpoint';
import { endpointParameter } from '../models/types/endpointParameter';
import { PipelineEndpoint } from '../models/types/pipelineEndpoint';
import { enumHandler } from './enumHandler';

const dowstreamToUpstream = (
  endpoint: DownstreamService & { selected: boolean; inUse: boolean },
  namespace: string,
) => {
  const api = new Api(
    endpoint.description,
    endpoint.summary,
    endpoint.method,
    endpoint.path,
    endpoint.protocol,
    endpoint.requestBody?.type ?? '',
    true,
    namespace,
    endpoint.name,
    endpoint.tag,
    endpoint.parameters?.map((x) => parameterParser(x)) ?? [],
  );
  const pipelineElement = new PipelineEndpoint(
    {
      id: endpoint.id!,
      mapping: api.requestParameters.map((p) => ({ query: p.name, parameter: p.name })),
      type: PipelineElement.Endpoint,
    },
    0,
  );
  const pipeline = [pipelineElement];
  return new ApiDto(api, pipeline);
};

export const parameterParser = (param: DownstreamRequestParameter) => {
  let newParam = new endpointParameter(
    param.name,
    enumHandler.stringToEnum(ApiParameterArea, param.in.toLowerCase()),
    param.description ?? param.schema.description ?? '',
    param.required,
    param.schema.type === 'array',
    [],
    null,
  );
  switch (param.schema.type) {
    case 'array':
      return typeParser(param.schema.items!, newParam);
    default:
      return typeParser(param.schema, newParam);
  }
};

const typeParser = (schema: DownstreamServiceItem, param: endpointParameter) => {
  if(schema.format){
    switch (schema.format) {
      case 'date-time':
        param.type = ParameterTypes.DateTime;
        return param;
      case 'date':
        param.type = ParameterTypes.Date;
        return param;
      case 'uuid':
        param.type = ParameterTypes.UUID;
        return param;
      case 'float':
        param.type = ParameterTypes.Float;
        return param;
      case 'double':
        param.type = ParameterTypes.Double;
        return param;
      case 'int32':
        param.type = ParameterTypes.Int32;
        return param;
      case 'int64':
        param.type = ParameterTypes.Int64;
        return param;
    }
  }
  switch (schema.type) {
    case 'string':
      if (schema.enum && schema.enum?.length > 0) {
        param.type = ParameterTypes.Enum;
        param.enum = schema.enum;
        return param;
      }
      param.type = ParameterTypes.String;
      return param;

    case 'integer':
      param.type = ParameterTypes.Integer;
      return param;
    case 'number':
      param.type = ParameterTypes.Number;
      return param;
    case 'boolean':
      param.type = ParameterTypes.Boolean;
      return param;
    default:
      param.type = ParameterTypes.String;
      return param;
  }
};

export { dowstreamToUpstream };
