import styled from 'styled-components';

export const CodeEditor = styled.div`
  width: 100%;
  height: 100%;
  .editor-title {
    font-family: Roboto;
    font-size: 14px;
    height: 18px;
    font-weight: 400;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    -webkit-letter-spacing: 0.16px;
    -moz-letter-spacing: 0.16px;
    -ms-letter-spacing: 0.16px;
    letter-spacing: 0.16px;
    color: #666666;
  }
  .editor-window {
    border: 1px solid lightgray;
    width: 100%;
    height: calc(100% - 17px);
    min-width: 0;
  }
`;
