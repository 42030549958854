import React from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

function useToolBarHook() {


  const { t } = useTranslation();

  const [count, setCount] = React.useState(0);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  return {
    t,
    count,
    increment,
    decrement,
  };
}

export default useToolBarHook;
