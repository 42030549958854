import styled from 'styled-components';

export const ParameterMapper = styled.div`
  width: 100%;
  height: 100%;
  .plasma-row {
    margin: 0 !important;
  }
  .ant-col {
    padding: 0 !important;
  }
  .parameterValueContainer {
    display: flex;
    padding-left: 10px;
    flex-direction: row;
    .input {
      flex-grow: 1;
    }
  }
`;
