import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import { SwaggerService } from '../../../models/types/swaggerService';
import { grpcService } from '../../../models/types/grpcService';
import { EndpointDto } from '../../../models/dtos/EndpointDto';
import { agent } from '../../../api/agent';
import { dataHooks } from '../../../hooks/dataHooks';
import { ApiFabricatorDto } from '../../../models/dtos/ApiFabricatorDto';
import { useTranslation } from '@plasma/ui.utils.i18n';

export default function useConfirmationStep(
  type: 'create' | 'update',
  serviceSource: SwaggerService | grpcService,
  apiInfos: ApiFabricatorDto | undefined,
  services: any,
) {

  const { t } = useTranslation();
  const {
    done: dataImported,
    loading: saveServices,
    execute: postServices,
  } = dataHooks.useApiCall(
    () =>{
      const kp = new EndpointDto(serviceSource as SwaggerService, services as DownstreamService[], {
        data: apiInfos?.document ?? '',
        version: apiInfos?.version ?? (serviceSource as SwaggerService).swaggerFile?.[0].name ?? '',
        title: apiInfos?.title ?? '',
        inUse: true,
      })
     return type === 'update'
        ? agent.endpointGroup.update(
            new EndpointDto(serviceSource as SwaggerService, services as DownstreamService[], {
              data: apiInfos?.document ?? '',
              version: apiInfos?.version ?? (serviceSource as SwaggerService).swaggerFile?.[0].name ?? '',
              title: apiInfos?.title ?? '',
              inUse: true,
            }),
          )
        : agent.endpointGroup.save(
            new EndpointDto(serviceSource as SwaggerService, services as DownstreamService[], {
              data: apiInfos?.document ?? '',
              version: apiInfos?.version ?? '',
              title: apiInfos?.title ?? '',
              inUse: true,
            }),
          )},
    t('notification.endpoint.save.success'),
    null,
  );

  // Import handler
  const importHandler = () => {
      postServices();
  };

  return {
    saveServices,
    dataImported,
    importHandler,
  };
}
