import React, { useMemo } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useServiceCardHook from './ServiceCard.hook';
import * as styled from './ServiceCard.styles';
import { JsLogo } from '../../../core/logos/javascript';
import Icon from '@plasma/ui.general.icon';
import PopConfirm from '@plasma/ui.feedback.pop-confirm';
import OverflowMenu from '@plasma/ui.navigation.overflow-menu';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';
import ErrorIndicator from '../../../core/ErrorIndicator/ErrorIndicator';
import { ProcessorService } from '../../../../models/types/ProcessorService';
import { DownstreamEndpointShort } from '../../../../models/types/downstreamEndpointShort';
import { Badge } from 'antd';
import { MappedEndpoint } from '../../../../models/types/mappedEnpoint';

export interface ServiceCardProps {
  elementId?: string;
  index?: number;
  onSelectElement: (id: string) => void;
  className?: string;
  style?: React.CSSProperties;
  onAddProcessor: (index: number, key: string, id: string) => void;
  onDeleteElement: (id: string) => void;
  selectedElementId: string | null;
  getRootElement: (id: string) => DownstreamEndpointShort | ProcessorService | undefined;
  getMappedElement: (id: string) => MappedEndpoint | ProcessorService | undefined
}

type Props = ServiceCardProps & ThemeOverride;

function ServiceCard(props: Props) {
  const { className, style, size, themeMode } = props;

  const logic = useServiceCardHook(props);
  const Badges = useMemo(() => {
    if (logic.element)
      if (logic.isEnpointService)
      return [
        <Badge key={'protocol'} >
          {(logic.element as DownstreamService).protocol?.toUpperCase()}
        </Badge>,
        <Badge className={`badge ${logic.element.method}`} key={'method'}>
          {logic.element.method.toUpperCase()}
        </Badge>,
      ]
      else
        switch (logic.element.method) {
          case 'javascript':
            return [<JsLogo key={'logo'} height={20} width={20} />];
        }
  }, [props.elementId]);

  const Infos = useMemo(() => {
    if (logic.mappedElement && 'error' in logic.mappedElement)
      return logic.mappedElement.error ? [<Icon key="error" name="error" className="error-icon" />] : null;
     else if (logic.mappedElement && 'mapping' in logic.mappedElement)
      return logic.mappedElement.mapping?.some(p=>p.parameter===null) ? [<Icon key="error" name="error" className="error-icon" />] : null;
    return null;
  }, [props.getMappedElement]);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ServiceCard
        onClick={logic.clickHandler}
        className={`service-card ${className ?? ''}`.trim()}
        style={style}
      >
        <div className={`card ${logic.selected ? 'selected' : ''}`}>
          {logic.element ? (
            <>
              <div className="top-bar">
                <div className="info-container">{Infos}</div>
                <div className="badge-container">{Badges}</div>
              </div>
              <div className="info-container">
                <h1 className="name">{logic.element.name}</h1>
                {'path' in logic.element ? <div><h5 className="path">{logic.element.path}</h5></div> : null}
              </div>
            </>
          ) : (
            <ErrorIndicator message="invalid Service !" />
          )}
          {logic.selected ? (
            <div className="bottom-bar">
              {logic.isEnpointService ? <OverflowMenu items={logic.menuItems} /> : null}
              <PopConfirm
                title={'Delete'}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  props.onDeleteElement(props.elementId!);
                }}
              >
                <Icon name="delete" className="delete-icon" />
              </PopConfirm>
            </div>
          ) : null}
        </div>
      </styled.ServiceCard>
    </ThemeProvider>
  );
}

export default ServiceCard;
