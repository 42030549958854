import { UploadFile } from 'antd/lib/upload/interface';
import { ServiceType } from '../enums/ServiceType';
import { SwaggerSource } from '../enums/SwaggerSource';

export class SwaggerInfoRequestBody {
  method: SwaggerSource;
  url: string;
  path: string;
  jsonFile: string;

  constructor(method: SwaggerSource, url: string = '', path: string = '', jsonFile: string = '') {
    this.method = method;
    this.url = url;
    this.path = path;
    this.jsonFile = jsonFile;
  }
}
