import { useMemo, useState } from 'react';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import { SwaggerService } from '../../../models/types/swaggerService';
import { grpcService } from '../../../models/types/grpcService';
import { ServiceType } from '../../../models/enums/ServiceType';
import { urlChecker } from '../../../utils/urlChecker';
import { debounce } from 'lodash';
import { ApiFabricatorDto } from '../../../models/dtos/ApiFabricatorDto';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { dataHooks } from '../../../hooks/dataHooks';
import { SwaggerSource } from '../../../models/enums/SwaggerSource';
import { SwaggerInfoRequestBody } from '../../../models/types/swaggerInfoRequestBody';
import { agent } from '../../../api/agent';
import { useStore } from '../../../stores/store';
import { useNavigate, useParams } from '@plasma/ui.application.router';
import notification from '@plasma/ui.feedback.notification/dist/notification';
import { EndpointDto } from '../../../models/dtos/EndpointDto';

export default function useConfigurationStep(
  id: string | undefined,
  type: 'create' | 'update',
  setStep: React.Dispatch<React.SetStateAction<number>>,
  setServices: React.Dispatch<
    React.SetStateAction<
      | (DownstreamService & {
          selected: boolean;
          inUse: boolean;
        })[]
      | undefined
    >
  >,
) {
  const { t } = useTranslation();
  const { LayoutStore } = useStore();
  const navigate = useNavigate();
  // State for the service type
  const [serviceType, setServiceType] = useState(ServiceType.Swagger);
  // State for service source information
  const [serviceSource, setServiceSource] = useState<SwaggerService | grpcService>();
  // State for initial service source information
  const [initialData, setInitialData] = useState<SwaggerService | grpcService>();

  const { data: initialDataSource } = dataHooks.useGetInitialData(
    () =>
      agent.endpointGroup
        .getCompleteById(id!)
        .then((response) => {
          LayoutStore.breadcrumbItem = response.name;
          setInitialData(
            new SwaggerService(
              response.id,
              response.groupName,
              response.name,
              SwaggerSource.Local,
              response.documentations.filter((d) => d.inUse).length > 0
                ? [
                    {
                      uid: response.documentations.find((d) => d.inUse)!.id,
                      name: response.documentations.find((d) => d.inUse)!.version,
                      type: 'uuid',
                    },
                  ]
                : undefined,
              response.serviceUrl,
              response.additionalUrls,
              response.balancingPolicy,
              response.definitionPath ?? '',
            ),
          );
          return response;
        })
        .catch((error) => {
          notification.error(
            (error as any)?.response?.data?.message,
            (error as any)?.response?.data?.description,
            undefined,
            'small',
            3,
          );
          navigate('/services', { replace: true });
        }),
    true,
    type === 'update',
  );

  // Service source change handler
  const onServiceSourceChange = debounce((value: SwaggerService) => {
    setServiceSource({ ...value, id: initialData?.id ?? null });
  }, 300);

  const urlsValid = serviceSource
    ? [serviceSource.url, ...serviceSource.additionalUrls].every((url) => urlChecker(url))
    : false;

  // API handling to get Endpoints
  const {
    data: apiInfos,
    loading: loadServices,
    execute: fetchServices,
  } = dataHooks.useApiCall<ApiFabricatorDto>(null, t('notification.endpointExtraction.success'), null);

  // State to indicate if step 1 is done
  const step1Done = useMemo(() => {
    switch (serviceSource?.type) {
      case ServiceType.Swagger:
        return (
          serviceSource.groupName.length > 0 &&
          serviceSource.url.length > 0 &&
          urlsValid &&
          ((serviceSource.swaggerSource === SwaggerSource.Local && serviceSource.swaggerFile.length > 0) ||
            (serviceSource.swaggerSource === SwaggerSource.Remote && serviceSource.swaggerPath.length > 0))
        );
      case ServiceType.gRPC:
        return false;
      default:
        return false;
    }
  }, [serviceSource]);

  // Action after step 1 is finished
  const firstStepHandler = () => {
    switch (serviceSource?.type) {
      case ServiceType.Swagger:
        swaggerUpload();
        break;
    }
  };

  // Upload methods for a local or remote swagger file
  const swaggerUpload = () => {
    let source = serviceSource as SwaggerService;
    let method = source.swaggerSource;
    let url = source.url;
    let path = source.swaggerPath;
    const isFile = source.swaggerFile[0]?.originFileObj ? true : false;
    let jsonFile = source.swaggerFile[0]?.originFileObj ?? (source.swaggerFile[0]?.uid as any);
    const request = (body: SwaggerInfoRequestBody) =>
      fetchServices(() =>
        agent.endpointFabricator.fabricateSwaggerInfo(body).then((response) => {
          setStep(1);
          if (type === 'create') {
            setServices(response.endpoints.map((path) => ({ ...path, selected: false, inUse: false })));
            return response;
          } else {
            setServices(
              response.endpoints.map((path) => ({
                ...path,
                selected:
                  initialDataSource?.downstreamEndpoints.some(
                    (x) => x.path === path.path && x.method === path.method,
                  ) ?? false,
                name:
                  initialDataSource?.downstreamEndpoints.find((x) => x.path === path.path && x.method === path.method)
                    ?.name ?? path.name,
                inUse:
                  initialDataSource?.downstreamEndpoints.find((x) => x.path === path.path && x.method === path.method)
                    ?.inUse ?? false,
                id:
                  initialDataSource?.downstreamEndpoints.find(
                    (y) => path.path === y.path && path.method === y.method && y.inUse,
                  )?.id ?? null,
              })),
            );
            return response;
          }
        }),
      );

    switch (source.swaggerSource) {
      case SwaggerSource.Remote:
        request(new SwaggerInfoRequestBody(method, url, path, ''));
        break;
      case SwaggerSource.Local:
        if (isFile) {
          const reader = new FileReader();
          reader.readAsText(jsonFile);
          reader.onload = () => {
            request(new SwaggerInfoRequestBody(method, url, path, reader.result?.toString()));
          };
        } else {
          request(new SwaggerInfoRequestBody(method, url, path, jsonFile));
        }
    }
  };

  // Check if a simple  update can be executed
  const enableUpdate = useMemo(() => {
    if (initialData && serviceSource) {
      if (
        urlsValid &&
        (serviceSource?.groupName?.length ?? 0) > 0 &&
        (serviceSource as SwaggerService).swaggerSource === SwaggerSource.Local &&
        (serviceSource as SwaggerService).swaggerFile.length > 0 &&
        (initialData as SwaggerService).swaggerFile?.[0].type === 'uuid' &&
        (serviceSource as SwaggerService).swaggerFile[0].name === (initialData as SwaggerService).swaggerFile?.[0].name
      )
        return true;
      return false;
    }
  }, [serviceSource]);

  const { loading: isSaving, execute: quickSaveServices } = dataHooks.useApiCall(
    () =>
      agent.endpointGroup.update(
        new EndpointDto(
          serviceSource as SwaggerService,
          initialDataSource?.downstreamEndpoints as DownstreamService[],
          {
            data: apiInfos?.document ?? '',
            version: apiInfos?.version ?? (serviceSource as SwaggerService).swaggerFile?.[0].name ?? '',
            title: apiInfos?.title ?? '',
            inUse: true,
          },
        ),
      ),
    t('notification.endpoint.save.success'),
    null,
  );

  const quickSaveHandler = ()=>{
    quickSaveServices()
  }

  return {
    isSaving,
    quickSaveHandler,
    serviceSource,
    enableUpdate,
    firstStepHandler,
    apiInfos,
    loadServices,
    serviceType,
    setServiceType,
    onServiceSourceChange,
    setInitialData,
    step1Done,
    initialDataSource,
    urlsValid,
    initialData,
  };
}
