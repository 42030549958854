import React, { memo } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useApiTestFormHook from './ApiTestForm.hook';
import * as styled from './ApiTestForm.styles';
import Form from '@plasma/ui.input.form';
import Row from '@plasma/ui.layout.row';
import FormField from '@plasma/ui.input.form-field';
import Col from '@plasma/ui.layout.col';
import Switch from '@plasma/ui.input.switch';
import Select from '@plasma/ui.input.select/dist/select';
import TextInput from '@plasma/ui.input.text-input';
import TextArea from '@plasma/ui.input.text-area';
import Button from '@plasma/ui.general.button';
import { Api } from '../../../../models/types/api';
import ParameterMapper from '../../../shared/ParameterMapper';

export interface ApiTestFormProps {
  className?: string;
  style?: React.CSSProperties;
  apiProps: Api;
  onClose: () => void;
}

type Props = ApiTestFormProps & ThemeOverride;

function ApiTestForm(props: Props) {
  const { className, style, size, themeMode } = props;

  const logic = useApiTestFormHook(props);
  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ApiTestForm className={`api-test-form ${className ?? ''}`.trim()} style={style}>
        <Form
          onChange={(x) => {
            logic.valueRef.current = x;
          }}
          initialValues={logic.defaultProps}
          //   submitCallback={logic.submitHandler}
          id="apiform"
          className="form"
        >
          <Row>
            <Col span={12}>
              <FormField valuePropName="checked" required label={logic.t('apiTestForm.customAuth')} name="customAuth">
                <Switch
                  onClick={(x) => {
                    logic.setAuthOn(x);
                  }}
                  label={logic.t('apiTestForm.active')}
                />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormField required label={logic.t('apiTestForm.authMethod')} name="authMethod">
                <Select disabled={!logic.authOn} options={logic.authMethods} />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormField required label={logic.t('apiTestForm.token')} name="token">
                <TextInput disabled={!logic.authOn} kind="outlined" />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormField required label={logic.t('apiTestForm.parameter')} name="parameter">
                <ParameterMapper editableNames allowAdd />
              </FormField>
            </Col>
          </Row>
          <Row className="lastRow">
            <Col span={24}>
              <FormField required label={logic.t('apiTestForm.requestBody')} name="requestBody">
                <TextArea />
              </FormField>
            </Col>
          </Row>
        </Form>
        <Row gutter={[10, 10]} justify="end">
          <Col>
            <Button title={logic.t('apiTestForm.test')} />
          </Col>
          <Col>
            <Button type="secondary" title={logic.t('apiTestForm.cancel')} onClick={props.onClose} />
          </Col>
        </Row>
      </styled.ApiTestForm>
    </ThemeProvider>
  );
}

export default memo(ApiTestForm) as unknown as typeof ApiTestForm;
