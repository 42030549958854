import React, { useEffect, useState } from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import translations from './i18n';
import help from './service-swagger-documentation.help';
import { dataHooks } from '../../../hooks/dataHooks';
import { agent } from '../../../api/agent';
import { useParams } from '@plasma/ui.application.router';
import keycloak from '../../../../keycloak';
import { EndpointInputDto } from '../../../models/dtos/EndpointDto';
import { useStore } from '../../../stores/store';
import notification from '@plasma/ui.feedback.notification/dist/notification';

function useServiceSwaggerDocumentationHook() {
  useResourceBundle(translations, undefined, 'a66ac8c8-2414-4048-9847-e2b59d7c5215');
  useHelpBundle(help);

  const { t } = useTranslation();
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const {LayoutStore} = useStore()
  const { data, loading, setData, refetch } = dataHooks.useGetInitialData<object>(
    () =>
      Promise.all([agent.endpointGroup.getUsedDocumentation(id!), agent.endpointGroup.getCompleteById(id!)]).then(
        (response) => {
          var test = response as [any, EndpointInputDto];
          LayoutStore.breadcrumbItem = test[1].name
          setTitle(test[0].info.title);
          //@ts-ignore
          var url = test[1].serviceUrl;
          test[0].servers = [{ url: url, description: 'Production Server' }];
          return test[0];
        },
      )
      .catch((error)=>{
        notification.error(error?.response?.data?.message, error?.response?.data?.description, undefined, 'small', 3);
      }),
    false,
    true,
  );

  useEffect(() => {
    return () => {
      LayoutStore.breadcrumbItem = undefined;
    };
  }, []);

  const downloadHandler = () => {
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${title} swagger`;

    // Append the link to the document and trigger a click
    document.body.appendChild(a);
    a.click();

    // Remove the link from the document
    document.body.removeChild(a);

    // Revoke the URL to free up resources
    URL.revokeObjectURL(url);
  };

  const requestInterceptor = (req: any): Request => {
    req.headers.Authorization = `Bearer ${keycloak.token}`;
    return req;
  };

  return {
    loading,
    t,
    downloadHandler,
    requestInterceptor,
    data,
  };
}

export default useServiceSwaggerDocumentationHook;
