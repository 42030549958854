import React, { useState } from 'react';
import beautify from 'js-beautify';
import Icon from '@plasma/ui.general.icon';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { DownstreamRequestParameter, DownstreamServiceBody } from '../../../models/types/downstreamEndpoint';
import { requestBodyToSourceCode } from '../../../utils/requestBodyToSourceCode';
import { parameterParser } from '../../../utils/downstreamToUpstream';
import ParameterCreator from '../parameter-creator';

export const Requestparameter = ({ value }: { value?: DownstreamRequestParameter[] }) => {
  const [expanded, setExpanded] = useState(false);
  const hasBody = value && value.length > 0 ? true : false;
  const { t } = useTranslation();
  const params = value?.map((p) => parameterParser(p));

  return (
    <>
      <div
        className={`parent ${!hasBody ? 'disabled' : ''}`}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {expanded ? <Icon className="icon" name="expand_more" /> : <Icon className="icon" name="chevron_right" />}
        <div>{t('label.requestParameter')}</div>
      </div>
      {expanded && hasBody ? (
        <div
          style={{
            marginLeft: 30,
            whiteSpace: 'pre-line',
            width: 'calc(100% - 30px)',
            backgroundColor: ' #f9f9f9',
          }}
        >
          <ParameterCreator lock={true} value={params} />
        </div>
      ) : null}
    </>
  );
};
export const Requestbody = ({ value }: { value?: {
  body: DownstreamServiceBody;
  type:string | null;
  description: string | null;
} | null }) => {
  const [expanded, setExpanded] = useState(false);
  const hasBody = value?.type ? true : false;
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`parent ${!hasBody ? 'disabled' : ''}`}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {expanded ? <Icon className="icon" name="expand_more" /> : <Icon className="icon" name="chevron_right" />}
        <div>{t('label.requestBody')}</div>
      </div>
      {expanded && hasBody ? (
        <div
          style={{
            padding: 5,
            marginLeft: 30,
            whiteSpace: 'pre-line',
            width: 'calc(100% - 30px)',
            backgroundColor: ' #f9f9f9',
          }}
        >
          {value ? beautify(value.type ?? '', { indent_size: 4 }) : ''}
        </div>
      ) : null}
    </>
  );
};

export const Responsebody = ({
  value,
}: {
  value:
    | {
        [key: string]: {
          body: DownstreamServiceBody | null;
          description: string | null;
          type: string | null;
        };
      }
    | null
    | undefined;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const hasResponse = value ? Object.keys(value).length > 0 : null;
  return (
    <div style={{ height: '100%' }}>
      <div
        className={`parent ${!hasResponse ? 'disabled' : ''}`}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {expanded ? <Icon className="icon" name="expand_more" /> : <Icon className="icon" name="chevron_right" />}
        <span>{t('label.responses')}</span>
      </div>
      {expanded && value ? (
        <>
          {Object.entries(value).map((x, ii) => (
            <div key={ii} style={{ marginLeft: 30, width: 'calc(100% - 30px)' }}>
              <span>{`${x[0]} ${x[1].description}`}</span>
              {x[1]?.body ? (
                <div
                  style={{
                    padding: 5,
                    whiteSpace: 'pre-line',
                    width: '100%',
                    backgroundColor: ' #f9f9f9',
                  }}
                >
                  {beautify(x[1]?.type ?? '', { indent_size: 4 })}
                </div>
              ) : null}
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};
