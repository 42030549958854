const pathToParams = (path: string) => {
  // TODO try to use url parse
  const queryPattern = /\?([^=]+)=\{([^}]+)}/g; // Regular expression to match ?...={...}
  const additionalQueriesPattern = /&([^=]+)=\{([^}]+)}/g;
  const pathPattern = /\/{([a-zA-Z0-9]+)}/g; // Regular expression to match /{...}/

  const firstQuery = (path?.match(queryPattern) || []).map((match) => ({
    query: match?.split('=')[0].replace(/^(\?|&)/, ''),
    parameter: match?.slice(match.indexOf('=') + 2, -1),
  }));
  const additionalQueries = (path?.match(additionalQueriesPattern) || []).map((match) => ({
    query: match?.split('=')[0].replace(/^(\?|&)/, ''),
    parameter: match?.slice(match.indexOf('=') + 2, -1),
  }));
  const queries = firstQuery.concat(additionalQueries);
  const paths = path?.match(pathPattern)?.map((match) => match?.slice(2, -1)) ?? [];
  return { queries: queries, paths: paths };
};

export { pathToParams };
