import React from 'react';
import * as styled from './DescriptionModalContent.styles';
import TextArea from '@plasma/ui.input.text-area';

export interface DescriptionModalContentProps {
  value: string;
  changeHandler: (value: string) => void;
  lock?: boolean;
}

export default function DescriptionModalContent(props: DescriptionModalContentProps) {
  return (
    <styled.DescriptionModalContent>
      <TextArea
        className="inputElement"
        onChange={(e) => {
          if (!props.lock) props.changeHandler(e.target.value);
        }}
        value={props.value}
      />
    </styled.DescriptionModalContent>
  );
}
