import styled from 'styled-components';

export const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  .rangeSelect {
      width: 150px;
    }
`;
