import styled from 'styled-components';

export const MappingArea = styled.div`

  height: 100%;

  .plasma-scrollable-area {
    height: 100%;

    background-color: green;

  }
  .gridla {
    top: -60px;
    min-height: calc(100% + 60px);
    width: 100%;
    position: absolute;
    background-color: #f0f0f0;
  }
  .place-holder {
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
  }

  .error-container {
    margin-left: 75px;
    margin-top: 10px;
    .error-icon {
      font-size: 25px;
      color: #ff6161;
    }
  }
  .addBar {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .circle {
      height: 25px;
      width: 25px;
      background-color: black;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .scrollContainer{height:100%}
`;
