function groupArray<T>(array: T[], key: string) {
  const grouped: { [key: string]: T[] } = {};

  for (const obj of array) {
    let _obj = obj as any;
    if (grouped[_obj[key]]) {
      grouped[_obj[key]].push(obj);
    } else {
      grouped[_obj[key]] = [obj];
    }
  }

  return grouped;
}
export {groupArray}
