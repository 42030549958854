import humanizeString from 'humanize-string';

const enumToOptions = (enumObject: any, humanize?: boolean): { value: any; label: string }[] =>
  Object.keys(enumObject)
    .map((key) => ({
      value: enumObject[key],
      label: humanize ? humanizeString(key) : key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export { enumToOptions };
