import React, { memo, ReactNode } from 'react';
import Button from '@plasma/ui.general.button';
import TextInput from '@plasma/ui.input.text-input';
import ScrollableArea from '@plasma/ui.layout.scrollable-area';
import { Icon } from '@plasma/ui.general.icon';
import * as styled from './EnumModalContent.styles';
export interface EnumModalContentProps {
  value: string[];
  changeHandler: (action: 'add' | 'delete' | 'update', index?: number, value?: string) => void;
  lock?: boolean;
}

export default function EnumModalContent(props: EnumModalContentProps) {
  return (
    <styled.EnumModalContent>
      <div className="listContainer">
        <ScrollableArea className="scrollArea">
          {props.value.map((item, ii) => (
            <div key={ii} className="listElementContainer">
              <TextInput
                className="inputElement"
                onChange={(e) => {
                  if(!props.lock)
                  props.changeHandler('update', ii, e.target.value);
                }}
                placeholder="Enum value"
                value={item}
              />
              {!props.lock ? (
                <Button
                  onClick={() => {
                    props.changeHandler('delete', ii);
                  }}
                  type="text"
                  icon={<Icon name="remove" />}
                />
              ) : null}
            </div>
          ))}
        </ScrollableArea>
      </div>
      {!props.lock ? (
        <Button
          onClick={() => {
            props.changeHandler('add');
          }}
          title="Add"
          className="addButton"
        />
      ) : null}
    </styled.EnumModalContent>
  );
}
