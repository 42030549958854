import React, { useMemo } from 'react';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { enumToOptions } from '../../../../utils/enumToOptions';
import { ServiceType } from '../../../../models/enums/ServiceType';
import { SwaggerSource } from '../../../../models/enums/SwaggerSource';
import { SwaggerService } from '../../../../models/types/swaggerService';
import { grpcService } from '../../../../models/types/grpcService';
import { SourceFormProps } from './SourceForm';
import { LoadBalancingPolicies } from '../../../../models/enums/LoadBalancingPolicies';

function useSourceFormHook(props: SourceFormProps) {
  const { t } = useTranslation();

  const serviceTypes = enumToOptions(ServiceType);
  const swaggerSources = enumToOptions(SwaggerSource);
const balancingPolicies = enumToOptions(LoadBalancingPolicies,true)

  const initialValues = useMemo(() => {
    let object = (() => {
      switch (props.serviceType) {
        case ServiceType.Swagger:
          return props.initialValues && props.initialValues.type === ServiceType.Swagger
            ? props.initialValues
            : new SwaggerService();
        case ServiceType.gRPC:
          return props.initialValues && props.initialValues.type === ServiceType.gRPC
            ? props.initialValues
            : new grpcService();
      }
    })();
    props.onChange(object);
    return object;
  }, [props.serviceType, props.initialValues]);



  return {
    t,
    balancingPolicies,
    serviceTypes,
    swaggerSources,
    initialValues,
  };
}

export default useSourceFormHook;
