import React, { memo, ReactNode, useState } from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import Button from '@plasma/ui.general.button';
import useFileUploadHook from './FileUpload.hook';
import * as styled from './FileUpload.styles';
import Upload from '@plasma/ui.input.upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import Popover from '@plasma/ui.display.popover';
import Icon from '@plasma/ui.general.icon';
export interface FileUploadProps {
  value?: UploadFile<any>[];
  onChange?: (value: UploadFile<any>[]) => void;
  className?: string;
  maxCount?: number;
  documentHistory: { id: string; version: string; title: string, inUse:boolean }[];
}

type Props = FileUploadProps & ThemeOverride;

function FileUpload(props: Props) {
  const { value, className, onChange, size, themeMode } = props;

  const logic = useFileUploadHook(props);
const [showHistory,setShowHistory] = useState(false)
  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.FileUpload className={`file-upload ${className ?? ''}`.trim()}>
        <Upload
          maxCount={props.maxCount}
          onChange={logic.changeHandler}
          type="button"
          fileList={logic.fileList}
          beforeUpload={(file, fileList) => {
            return false;
          }}
        />
        <Popover
        visible={showHistory}
          placement="right"
          onVisibleChange={()=>setShowHistory(!showHistory)}
          content={props.documentHistory.map((d) => (
            <Button
              onClick={() => {
                setShowHistory(false)
                logic.historyChangeHandler([
                  {
                    uid: d.id,
                    name: d.version,
                    type: 'uuid',
                  },
                ]);
              }}
              type="text"
              title={d.version}
            />
          ))}
          className="history-button"
          title={logic.t('doc-history')}
          trigger="click"
        >
          <Button onClick={()=>setShowHistory(!showHistory)} icon={<Icon name="history" />} />
        </Popover>
      </styled.FileUpload>
    </ThemeProvider>
  );
}

export default memo(FileUpload) as unknown as typeof FileUpload;
