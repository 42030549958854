import React from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import { EndpointSelectorProps } from './EndpointSelector';
import { CellType } from '@plasma/ui.display.data-grid/dist/types/data-grid-types';
import { SwaggerPath } from '../../../../models/types/swaggerpath';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';

function useEndpointSelectorHook(props: EndpointSelectorProps) {
  const { t } = useTranslation();

  const onCellUpdate = (
    newValue: string,
    rowIndex: number,
    columnId?: string,
    cell?: CellType<DownstreamService & { selected: boolean; inUse: boolean }>,
  ) => {
    let newArray = [...props.data];
    let element = newArray.at(rowIndex);
    //@ts-ignore
    if (element && columnId) element[columnId] = newValue;
    props.setServices(newArray);
  };




  return {
    t,
    onCellUpdate,

  };
}

export default useEndpointSelectorHook;
