import React from 'react';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { dataHooks } from '../../../../hooks/dataHooks';
import { agent } from '../../../../api/agent';
import { useNavigate } from '@plasma/ui.application.router';

function useToolBarHook() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { execute, loading } = dataHooks.useApiCall(null, t('notification.endpoints.export.success'), null);

  const handleUpload = (file: Blob) => {
    var reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = async function (evt) {
      if (evt.target !== null)
        if (evt.target.result !== null) {
          var data = reader.result;
          execute(() => agent.upstreamEndpoint.import(data).then(()=>{navigate("/")}));
        }
    };
  };

  return {
    t,
    handleUpload,
    loading,
  };
}

export default useToolBarHook;
