import React, { memo, ReactNode } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useConfirmationHook from './Confirmation.hook';
import * as styled from './Confirmation.styles';
import List from '@plasma/ui.display.list';
import Button from '@plasma/ui.general.button';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';

export interface ConfirmationProps {
  children?: ReactNode;
  selectedServices: (DownstreamService)[];
  importDone: boolean;
  /*
   * Defines custom className
   */
  className?: string;
  /*
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = ConfirmationProps & ThemeOverride;

function Confirmation(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useConfirmationHook(props);

  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.Confirmation className={`confirmation ${className ?? ''}`.trim()} style={style}>
        {!props.importDone ? (
          <>
            <h5>{logic.t('confirmation.import.description')}</h5>
            <List className="service-list" canExpand dataSource={logic.list} renderItem={(item) => `${item.title}`} />
          </>
        ) : (
          <>
            <h5>{logic.t('confirmation.done.description')}</h5>
            <Button title={logic.t('button.goToServices')} onClick={logic.doneHandler} />
          </>
        )}
      </styled.Confirmation>
    </ThemeProvider>
  );
}

export default memo(Confirmation) as unknown as typeof Confirmation;
