import React, { Suspense } from 'react';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/
import { Navigate, Route } from '@plasma/ui.application.router';
import LoadingOverlay from '../../../components/core/LoadingOverlay/LoadingOverlay';
import { useTranslation } from '@plasma/ui.utils.i18n';
import SmsSpin from '../../../components/core/SmsSpin/SmsSpin';

function Update() {
  const { t } = useTranslation();
  return (
    <Suspense
      fallback={<LoadingOverlay indicator={<SmsSpin message={t('loading.initial.message')} />} visible={true} />}
    >
      <ServiceBuilder type="update" />
    </Suspense>
  );
}

const ServiceBuilder = React.lazy(() => import('.'));
//@ts-ignore
const adminRole = window.env.ADMIN_ROLE;
export const ServiceBuilderRoutes = [
  new Route({
    permissions:[adminRole],
    path: '/services/edit',

    children: [
      new Route({
        path: '',
        element: () => <Navigate to="/services" replace />,
      }),
      new Route({
        path: '/services/edit/:id',
        element: Update,
      }),
    ],
  }),
  new Route({
    permissions:[adminRole],
    path: '/services/create',
    element: ServiceBuilder,
    children: [],
  }),
];
