import React from 'react';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/

import { Route } from '@plasma/ui.application.router';

const ApiExport = React.lazy(() => import('.'));

export const ApiExportRoutes = [
  new Route({
    path: 'apis/export',
    element: ApiExport,
    children: [],
  }),
];
