import React, { useRef, useState } from 'react';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { MappingAreaProps } from './mapping-area';
import { useXarrow } from 'react-xarrows';

function useMappingAreaHook(props: MappingAreaProps) {
  const { t } = useTranslation();

  // State for showing connection arrows
  const [showConnections, setShowConnection] = useState(true);

  // Temporare layout status during dragging
  const tempLayoutRef = useRef<ReactGridLayout.Layout[]>([]);

  const updateXarrow = useXarrow();

  // Function for layout changes during dragging
  const onLayoutChange = (layout: ReactGridLayout.Layout[]) => {
    tempLayoutRef.current = layout;
  };

  // Function for final layout changes after dragging/dropping
  const onFinalChange = () => {
    let newArray = [...tempLayoutRef.current];
    let newElement = newArray.find((x) => x.i === '__dropping-elem__');
    if (newElement && props.draggedItem) {
      newElement.i = props.draggedItem.id!;
      if (newElement.i == 'Processor')
      {
        props.onAddProcessor(newElement.y)}
      else props.onAddEndpointService(props.draggedItem?.id!, newArray);
    }
    else props.onUpdateMapping(newArray);
    updateXarrow();
    setShowConnection(true);
  };

  return {
    t,
    showConnections,
    setShowConnection,
    onLayoutChange,
    onFinalChange,
  };
}

export default useMappingAreaHook;
