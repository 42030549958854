import React, { memo, ReactNode } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useLayoutHook from './layout.hook';
import * as styled from './layout.styles';
import themes from './themes';
import { Outlet } from '@plasma/ui.application.router';
import Breadcrump from '../../core/Breadcrump/Breadcrump';

export interface LayoutProps {
  children?: ReactNode;
  /*
   * Defines custom className
   */
  className?: string;
  /*
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = LayoutProps & ThemeOverride;

function Layout(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const { t } = useLayoutHook();

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.Layout
        className={`layout ${className ?? ''}`.trim()}
        style={style}
        backgroundType="lit"
        id="tsd"
        header={<Breadcrump />}
      >
        <Outlet />
      </styled.Layout>
    </ThemeProvider>
  );
}

export default memo(Layout) as unknown as typeof Layout;
