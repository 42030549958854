import baseStyled, { ThemedCssFunction, ThemedStyledInterface, css as baseCss } from 'styled-components';

import { ServiceBuilderTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<ServiceBuilderTheme>;
const css = baseCss as ThemedCssFunction<ServiceBuilderTheme>;

export const ServiceBuilder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  .stepContainer {
    min-width: 610px;
    width: 40%;
    margin-bottom: 20px;
  }
  .titleContainer {
    display: flex;
    flex-direction: row;
    .nextButton {
      margin-left: auto;
    }
  }
  .contentContainer {
    margin-top: 10px;
    flex-grow: 1;
    width: 100%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: auto;
  }
`;
