import React from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import help from './ServiceForm.help';
import { ServiceFormProps } from './ServiceForm';
import { translations } from '../../../../i18n';
import { State } from '../../../shared/ParameterMapper/ParameterMapper';
import { dataHooks } from '../../../../hooks/dataHooks';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';
import { agent } from '../../../../api/agent';

function useServiceFormHook(props: ServiceFormProps) {
  useResourceBundle(translations, undefined, 'f36d127a-17c2-423e-aa5c-16e29d7459a2');
  useHelpBundle(help);

  const { t } = useTranslation();

  const { data: endpoint, loading } = dataHooks.useGetInitialData<DownstreamService>(
    () => agent.downStreamEndpoint.getById(props.endpoint.id!),
    false,
    true,
  );

  const paramMapping =
    props.mappedEndpoint?.mapping?.map((p) => ({
      ...p,
      parameter:
        props.context.find((c) => c.label === p.parameter)?.label ??null
    })) ?? [];

  const changeHandler = (x: State[] | undefined) => {
    if (x) props.updateMappedService(props.endpoint.id!, x);
  };
  return {
    t,
    paramMapping,
    changeHandler,
    endpoint,
    loading
  };
}

export default useServiceFormHook;
