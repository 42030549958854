import OverflowMenu, { OverflowMenuItem } from '@plasma/ui.navigation.overflow-menu';
import React, { ReactNode } from 'react';
import Popover from '@plasma/ui.display.popover';
import Icon from '@plasma/ui.general.icon';


interface Props {
  children?: ReactNode
  icon?:ReactNode
}

export default function ServiceMenu(props: Props) {
  return (
    <Popover
    destroyTooltipOnHide
      overlayInnerStyle={{ minWidth: 'unset',paddingLeft:0,paddingRight:0 }}
      placement="left"
      trigger="click"
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {props.children}
        </div>
      }
    >
     {props.icon??<Icon name="more_vert" />}
    </Popover>
  );
}
