import { Help } from '@plasma/ui.application.app';

const help: Help[] = [
  // {
  //   title: 'ApiCreateForm',
  //   description: 'description of ApiCreateForm',
  //   url: '/api-create-form',
  // },
];

export default help;
