import React, { useMemo, useState } from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import translations from './i18n';
import help from './api-export.help';
import { useNavigate } from '@plasma/ui.application.router';
import { dataHooks } from '../../../hooks/dataHooks';
import { agent } from '../../../api/agent';
import { debounce } from 'lodash';
import { ApiMeta } from '../../../models/types/apiMeta';
import { transformTableGrouping } from '../../../utils/transformTableGrouping';
import { CellType } from '@plasma/ui.display.data-grid/dist/types/data-grid-types';
import { fileLoader } from '../../../utils/fileLoader';

function useApiExportHook() {
  useResourceBundle(translations, undefined, 'ff4d83fe-4577-42c9-906d-7f1b2834906e');

  const { t } = useTranslation();
const [selectedIds,setSelectedIds] = useState<string[]>([])
  const { data, loading, setData } = dataHooks.useGetInitialData<(ApiMeta & { selected: boolean })[]>(
    () =>
      agent.upstreamEndpoint.getAllMeta().then((response) => {
        return response.map((e) => ({ ...e, selected: false })) as (ApiMeta & {
          selected: boolean;
        })[];
      }),
    false,
    true,
  );

  const { execute, loading: isDownloading } = dataHooks.useApiCall(
    null,
    t('notification.endpoints.export.success'),
    null,
  );

  const [filter, setFilter] = useState('');

  const filteredData = useMemo(() => {
    if (data) {
      let newArray = [...data];
      return newArray.filter((element) => Object.values(element).some((value) => value?.toString().toLowerCase().includes(filter)));
    }
    return [];
  }, [data, filter]);

  const debouncedSetFilteredData = debounce((filter) => {
    setFilter(filter);
  }, 300);

  const filterChange = (filter: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetFilteredData(filter.target.value);
  };

  const onRowSelect = (
    newValue: boolean,
    rowIndex: number,
    columnId?: string,
    cell?: CellType<ApiMeta & { selected: boolean }>,
  ) => {
    let newArray = [...data!];
    let element = newArray.at(rowIndex);
    //@ts-ignore
    if (element && columnId) element[columnId] = newValue;
    setData(newArray);
  };

  const onGroupSelect = (groupName: string) => {
    let groupingInfo = JSON.parse(transformTableGrouping(groupName));
    let newArray = [...data!];
    let element = newArray.filter((x) =>
      Object.entries(groupingInfo).every((k) => x[k[0] as keyof (ApiMeta & { selected: boolean })] == k[1]),
    );
    let newValue = !GroupSelected(groupName).selected;
    element.forEach((x) => {
      x.selected = newValue;
    });
    setData(newArray);
  };

  const GroupSelected = (groupName: string) => {
    let groupingInfo = JSON.parse(transformTableGrouping(groupName));
    let every = data
      ?.filter((x) =>
        Object.entries(groupingInfo).every((k) => x[k[0] as keyof (ApiMeta & { selected: boolean })] == k[1]),
      )
      .every((x) => x.selected === true);
    let some =
      !every &&
      data
        ?.filter((x) =>
          Object.entries(groupingInfo).every((k) => x[k[0] as keyof (ApiMeta & { selected: boolean })] == k[1]),
        )
        .some((x) => x.selected === true);
    return {
      selected: every,
      indeterminate: some,
    };
  };

  const downloadHandler = () => {
    execute(() =>
      agent.upstreamEndpoint.export(selectedIds).then((response) => {
        fileLoader(response);
        return response;
      }),
    );
  };

  return {
    t,
    filteredData,
    loading,
    filterChange,
    onRowSelect,
    onGroupSelect,
    GroupSelected,
    downloadHandler,
    isDownloading,
    setSelectedIds
  };
}

export default useApiExportHook;
