import React, { useMemo, useRef, useState } from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import help from './service-overview.help';
import { debounce } from 'lodash';
import { useNavigate } from '@plasma/ui.application.router';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import translations from './i18n';
import { dataHooks } from '../../../hooks/dataHooks';
import { agent } from '../../../api/agent';


function useOverviewHook() {
  useResourceBundle(translations, undefined, '62981f7a-08a7-43e1-afca-58f0e2abf802');
  useHelpBundle(help);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [filter, setFilter] = useState('');
  const { data, loading: isLoading } = dataHooks.useGetInitialData<(DownstreamService & { inUse: boolean,serverAvailable:boolean })[]>(
    () => agent.downStreamEndpoint.getAllComplete(),
    false,
    true,
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const filteredData = useMemo(() => {
    let newArray = [...(data ?? [])];
    const filteredResult =
      filter.length > 0
        ? newArray.filter(
            (x) =>
              x.groupName.toLowerCase().includes(filter.toLowerCase()) ||
              x.serviceName.toLowerCase().includes(filter.toLowerCase()) ||
              x.name.toLowerCase().includes(filter.toLowerCase()),
          )
        : newArray;
    return filteredResult;
  }, [data, filter]);

  const rangeValues = [
    { value: 24, label: t('last-24') },
    { value: 48, label: t('last-48') },
  ];

  const onFilterChange = debounce((filter: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(filter.target.value);
  }, 300);

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const navigateServiceSwagger = (id: string) => {
    window.open(`/services/swagger/${id}`, '_blank');
  };
  const { loading: isUploading, execute } = dataHooks.useApiCall(null, t("notification.document.upload.success"), null);

  const handleUpload = (id:string,files: FileList | null) => {

    if (files !== null) {
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);
      execute(()=>agent.endpointGroup.postDocument(id,formData)).finally(()=>{fileInputRef.current && (fileInputRef.current.value = "");})
    }
  };

  return {
    fileInputRef,
    data,
    t,
    rangeValues,
    isUploading,
    isLoading,
    onFilterChange,
    filteredData,
    handleNavigate,
    navigateServiceSwagger,
    handleUpload
  };
}

export default useOverviewHook;
