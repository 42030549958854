import styled from 'styled-components';

export const ServiceList = styled.div`
  width: 100%;
  height: 100%;


  .parent {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 5px;
    span {
      user-select: none;
    }
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
    :hover {
      background-color: #e5e5e5;
    }

    &.sub{
      padding-left: 20px;
    }
  }
  .child {
    margin-left: 40px;
    gap: 5px;
    display: flex;
    align-items: center;
    padding: 5px;
    &.util{
      margin-left: 0px;
    .util-logo{
    height: 14px;
    width: 14px;
  }
  }

    span {
    }
    cursor: grab;
    font-size: 14px;
    font-family: Roboto;
    :hover {
      background-color: #e5e5e5;
    }
  }
  .placed-child {
    color: lightgray;
    cursor: not-allowed;
    :hover {
      background-color: unset;
    }
    .icon {
      color: lightgray;
    }
  }
  .selected-child{
   &.placed-child{
    :hover {
      color: darkgray;
      .icon {
      color: darkgray;
    }
    }
   }
   background-color:#E5F0FC;
    :hover {
      background-color:#D0DAE5;
    }
  }


  .tab-container{
    height: calc(100% - 42px);
    margin-top: 10px;
  }
  .tab-pane{
height: 100%;
border: 1px solid lightgray;
  }
  .search-container {


    width: 100%;
height: 100%;

    overflow: auto;
    .plasma-scrollable-area{
      height: 100%;
      width: 100%;
    }
  }
`;
