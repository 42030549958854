import React, { memo, ReactNode } from 'react';
import Button from '@plasma/ui.general.button';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import useApiSwaggerDocumentationHook from './api-swagger-documentation.hook';
import * as styled from './api-swagger-documentation.styles';
import themes from './themes';
import LoadingOverlay from '../../../components/core/LoadingOverlay/LoadingOverlay';
import SmsSpin from '../../../components/core/SmsSpin/SmsSpin';

export interface ApiSwaggerDocumentationProps {
  children?: ReactNode;
  /*
   * Defines custom className
   */
  className?: string;
  /*
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = ApiSwaggerDocumentationProps & ThemeOverride;

function ApiSwaggerDocumentation(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const { t, data, loading,downloadHandler,requestInterceptor } = useApiSwaggerDocumentationHook();

  const handleSwaggerComplete = () => {};
  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.ApiSwaggerDocumentation className={`api-swagger-documentation ${className ?? ''}`.trim()} style={style}>
        {data && !loading ? (
          <>
            <Button onClick={downloadHandler} className="download-button" prefixIcon={{ name: 'Download' }} title="Download" />
            <SwaggerUI
            spec={data}
            onComplete={handleSwaggerComplete}
            requestInterceptor={(req) => requestInterceptor(req)}
            />
          </>
        ) : (
          <LoadingOverlay visible={loading} indicator={<SmsSpin />} />
        )}
      </styled.ApiSwaggerDocumentation>
    </ThemeProvider>
  );
}

export default ApiSwaggerDocumentation
