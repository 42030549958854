import styled from 'styled-components';

export const EnumModalContent = styled.div`
  .listContainer {
    height: 300px;
    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;

    .scrollArea {
      height: 100%;
      padding: 10px;

      .listElementContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 10px;

        .inputElement {
          flex-grow: 1;
        }
      }
    }
  }
  .addButton {
    margin-left: auto;
    margin-top: 10px;
  }
`;
