import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const EditFieldContainer = styled.div`
width: 100%;
  input[type='text'] {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    padding-block: 0px;
    padding-inline: 0px;
    &:focus {
      outline: none;
    }
  }
`;

interface Props {
  value: string;
  onChange: (value?: string) => void;
}

function EditField(props: Props) {
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState<string>();

  const onEnableEdit = () => {
    setEditValue(props.value);
    setEditMode(true);
  };

  const onDisableEdit = () => {
    props.onChange(editValue);
    setEditMode(false);
  };
  const inputRef = useRef(null);
  return (
    <EditFieldContainer>
      {!editMode ? (
        <div
          style={{ width: '100%', background:'transparent' }}
          onDoubleClick={onEnableEdit}
        >
          {!props.value || !(props.value.length>0) ? '-':props.value }
        </div>
      ) : (
        <input
        ref={inputRef}
          autoFocus={editMode}
          onBlur={onDisableEdit}
          type="text"
          value={editValue}
          onChange={(value) => setEditValue(value.target.value)}
        />
      )}
    </EditFieldContainer>
  );
}

export default EditField;
