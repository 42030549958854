import React, { useState } from 'react';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import translations from './i18n';
import { ResponseCreatorProps } from './response-creator';
import { response } from '../../../models/types/response';
import { bodyInfo } from '../../../models/types/bodyInfo';



function useResponseCreatorHook(props: ResponseCreatorProps) {
  useResourceBundle(translations, undefined, '9d3a718e-15e2-4a94-a372-7056b8221bcf');

  const { t } = useTranslation();

  const [internalParams, setInternalParams] = useState<response[]>([]);
  const [enumModal, setEnumModal] = useState<number | undefined>();
  const [tempEnum, setTempEnum] = useState<string[]>([]);
  const [descrModal, setDescrModal] = useState<number | undefined>();
  const [tempBodyInfo, setTempBodyInfo] = useState<bodyInfo>({type:'',content:''});

  const params = props.value ?? internalParams;

  const setState = (state: response[]) => {
    if (props.onChange) {
      props.onChange(state);
    }
    if (!props.value) {
      setInternalParams(state);
    }
  };

  const addHandler = () => {
    let newList = [...params];
    newList.push({ code: '', description:'', body:{type:'',content:''} });
    setState(newList);
  };

  const emptyData = [{ code: '', description:'', body:{type:'',content:''} }];

  const dataSource = params.length > 0 ? params : emptyData;
  const isEmpty = params.length === 0;
  const removeHandler = (index: number) => {
    let newList = [...params];
    newList = newList.filter((element, ii) => ii !== index);
    setState(newList);
  };

  const changeHandler = (index: number, parameter: keyof response, value: unknown) => {
    const newList = [...params];
    let item = newList.at(index);
    if (item != undefined) {
      (item[parameter] as unknown) = value;
      setState(newList);
    }
  };

  const tempEnumChangeHandler = (action: 'add' | 'delete' | 'update', index?: number, value?: string) => {
    let newList = [...tempEnum];
    switch (action) {
      case 'add':
        newList.push('');
        break;
      case 'delete':
        newList = newList.filter((element, ii) => ii !== index);
        break;
      case 'update':
        newList[index!] = value!;
        break;
      default:
        break;
    }
    setTempEnum(newList);
  };

  const tempDescrChangeHandler = (value: bodyInfo) => {
    setTempBodyInfo(value);
  };

  const saveTempBody = () => {
    const newList = [...params];
    let item = newList.at(descrModal!);
    if (item != undefined) {
      item.body = tempBodyInfo;
      setState(newList);
      setTempBodyInfo({type:'',content:''});
      setDescrModal(undefined);
    }
  };

  const openDescrModal = (index: number | undefined) => {
    setTempBodyInfo(params[index!].body);
    setDescrModal(index);
  };

  const closeDescrModal = () => {
    setTempBodyInfo({type:"",content:""});
    setDescrModal(undefined);
  };


  const closeEnumModal = () => {
    setTempEnum([]);
    setEnumModal(undefined);
  };
  return {
    t,
    dataSource,
    addHandler,
    removeHandler,
    changeHandler,
    enumModal,
    tempEnum,
    tempEnumChangeHandler,
    closeEnumModal,
    tempDescrChangeHandler,
    saveTempBody,
    tempBodyInfo,
    descrModal,
    openDescrModal,
    closeDescrModal,
    isEmpty,
  };
}

export default useResponseCreatorHook;
