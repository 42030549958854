import {
  DownstreamServiceBody,
  DownstreamServiceItem,
  DownstreamServiceProperties,
} from '../models/types/downstreamEndpoint';

function requestBodyToSourceCode(
  requestBody: DownstreamServiceBody | DownstreamServiceItem | DownstreamServiceProperties,
): string | null {
  switch (requestBody?.type) {
    case 'array':
      if (requestBody.items) {
        const arrayValue = requestBodyToSourceCode(requestBody.items as DownstreamServiceItem);
        return `${arrayValue}[]`;
      }
      return null;
    case 'object':
      const bodies = requestBody?.properties ? true : false;
      const value = bodies
        ? Object.entries(requestBody.properties!).map((x) => `${x[0]}:${requestBodyToSourceCode(x[1])}`)
        : `additionalProps:${requestBodyToSourceCode(requestBody.items as DownstreamServiceItem)}`;

      return `{${value}}`;

    default:
      return (requestBody as DownstreamServiceBody).enum
        ? (requestBody as DownstreamServiceBody).enum!.map((v) => `"${v}"`).join('|')
        : (requestBody as DownstreamServiceBody).type;
  }
}

export { requestBodyToSourceCode };
