import baseStyled, { ThemedCssFunction, ThemedStyledInterface, css as baseCss } from 'styled-components';

import { ServiceSwaggerDocumentationTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<ServiceSwaggerDocumentationTheme>;
const css = baseCss as ThemedCssFunction<ServiceSwaggerDocumentationTheme>;

export const ServiceSwaggerDocumentation = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  .download-button {
    position: absolute;
    right: 0px;
    top: 0px;
  }
`;
