import { makeAutoObservable } from 'mobx';

export default class LayoutStore {
  breadcrumbItem: string | undefined = undefined;
  token:string | undefined = undefined

  constructor() {
    makeAutoObservable(this);
  }
}
