import styled from 'styled-components';

export const BodyModalContent = styled.div`
  .content-field {
    width: 200px;
  }
  .body-field {
    margin-top: 10px;
    min-height: 250px;
  }
`;
