import { ProcessorService } from '../models/types/ProcessorService';
import { MappedEndpoint } from '../models/types/mappedEnpoint';
import { PipelineEndpoint } from '../models/types/pipelineEndpoint';
import { PipelineProcessor } from '../models/types/pipelineProcessor';
import { PipelineElement } from '../models/enums/PipelineElement';
import { v4 as uuidv4 } from 'uuid';

const pipelineFromMapping = (
  endpoints: MappedEndpoint[],
  processors: ProcessorService[],
  pipeline: ReactGridLayout.Layout[],
) => {
  let allElements: (MappedEndpoint | ProcessorService)[] = endpoints.concat(processors as any);
  return pipeline.map((x, ii) => {
    switch (allElements.find((y) => y.id === x.i)?.type) {
      case PipelineElement.Endpoint:
        return new PipelineEndpoint(allElements.find((y) => y.id === x.i) as MappedEndpoint, ii);
      case PipelineElement.Processor:
        return new PipelineProcessor(allElements.find((y) => y.id === x.i) as ProcessorService, ii);
      default:
        break;
    }
  });
};

const mappingFromPipeline = (pipeLine: string) => {
  let parsedPipeline = JSON.parse(pipeLine) as (PipelineEndpoint | PipelineProcessor)[];
  let processors: ProcessorService[] = [];
  let mappedEndpoints: MappedEndpoint[] = [];
  let mappingLayout: ReactGridLayout.Layout[] = [];
  parsedPipeline.forEach((p, ii) => {
    switch (p.type) {
      case PipelineElement.Endpoint:
        mappingLayout.push({ x: 0, y: ii, w: 1, h: 1, i: (p as PipelineEndpoint).downstreamEndpointId });
        mappedEndpoints.push({
          id: (p as PipelineEndpoint).downstreamEndpointId,
          type: PipelineElement.Endpoint,
          mapping: Object.entries((p as PipelineEndpoint).parameterMapping)?.map((e) => ({
            query: e[0],
            parameter: e[1],
          })),
        });
        break;
      case PipelineElement.Processor:
        let uuid = uuidv4();
        mappingLayout.push({ x: 0, y: ii, w: 1, h: 1, i: uuid });
        processors.push(new ProcessorService(uuid, p.name, p.implementation));
        break;
      default:
        break;
    }
  });
  return {
    pipeline: mappingLayout,
    processors: processors,
    mappedEndpoints: mappedEndpoints,
  };
};

export const pipelineService = {
  pipelineFromMapping,
  mappingFromPipeline,
};
