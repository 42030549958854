import baseStyled, { ThemedCssFunction, ThemedStyledInterface, css as baseCss } from 'styled-components';

import { DetailsTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<DetailsTheme>;
const css = baseCss as ThemedCssFunction<DetailsTheme>;

export const Details = styled.div`
  width: 100%;
  height: 100%;
  min-width: 830px;
overflow: auto;
  position: relative;
  .tab-container {
    height: 100%;
  }
  .plasma-tabs {
    height: 100%;
    .ant-tabs-content {
      overflow: auto;
      height: 100%;

      .createForm {
        width: 100%;
        padding-top: 10px;
        height: 100%;
        min-height: 700px;
      }
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: absolute;
    top: 15px;
    right: 0px;
  }
`;
