import styled from 'styled-components';

export const ApiMapping = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-width: 1190px;
  padding-bottom: 8px;
  align-content: flex-end;
  .service-list-container {
    max-width: 420px;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 250px;
    height: 100%;
    .searchInput {
      width: 200px;
    }
  }
  .mapping-container {
    border: 1px solid lightgray;
    align-self: flex-end;
    width: 400px;
    min-width: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    height: calc(100% - 0px);
  }
  .setting-container {
    min-width: 0;
    padding: 10px;
    border: 1px solid lightgray;
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;

    .config-container {
      height: calc(100%);
    }
  }
`;
