import FormField from '@plasma/ui.input.form-field';
import Select from '@plasma/ui.input.select/dist/select';
import React from 'react';
import TextInput from '@plasma/ui.input.text-input';
import Col from '@plasma/ui.layout.col';

export default function GrpcForm() {
  return null;
  // <Col span={8}>
  //   <FormField required label="URL" name={'url'}>
  //     <TextInput kind="outlined" />
  //   </FormField>
  // </Col>
}
