import { Help } from '@plasma/ui.application.app';

const help: Help[] = [
  // {
  //   title: 'ApiSwaggerDocumentation',
  //   description: 'description of ApiSwaggerDocumentation',
  //   url: '/api-swagger-documentation',
  // },
];

export default help;
