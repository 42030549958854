import React, { useState } from 'react';
import { ServiceListProps } from './service-list';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { debounce } from 'lodash';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';
import { groupArray } from '../../../../utils/groupArray';
import { JsLogo } from '../../../core/logos/javascript';
import { DownstreamEndpointShort } from '../../../../models/types/downstreamEndpointShort';
import { KeyOutlined } from '@ant-design/icons';

function useServiceListHook(props: ServiceListProps) {

  const [expand, setExpanded] = useState<string[]>([]);
  const { t } = useTranslation();
  const [filteredServices, setFilteredServices] = useState(groupServicesToList([...props.services]));

  const selectHandler = (index: string) => {
    let newArray = [...expand];
    let isExpanded = expand.some((x) => x === index);
    if (!isExpanded) newArray.push(index);
    else newArray = newArray.filter((x) => x !== index);
    setExpanded(newArray);
  };

  const isExpanded = (index: string) => {
    return expand.some((x) => x === index);
  };

  const onFilterChange = debounce((filter: string) => {
    let newArray = [...props.services];
    const filteredResult =
      filter.length > 0
        ? newArray.filter(
            (x) =>
              x.serviceName.toLowerCase().includes(filter.toLowerCase()) ||
              x.name.toLowerCase().includes(filter.toLowerCase()),
          )
        : newArray;
    setFilteredServices(groupServicesToList(filteredResult));
  }, 300);

  let mappedCheck = (id: string) => {
    return props.mappedServices.some((x) => x.id === id);
  };

  return { mappedCheck, t, filteredServices, onFilterChange,expand,setExpanded,selectHandler,isExpanded };
}
export default useServiceListHook;

const groupServicesToList = (services: DownstreamEndpointShort[]) => {
  const listObjects: { [key: string]: { [key: string]: DownstreamEndpointShort[] } } = {};
  Object.entries(groupArray(services, 'groupName')).forEach((g) => {
    const key = g[0];
    listObjects[key] = groupArray(g[1], 'serviceName');
  });

  return listObjects;
};
