import styled from 'styled-components';

export const FileUpload = styled.div`
  width: 100%;
  height: 62px;
  .gsWbbF.ant-btn.plasma-button.ant-btn-text{
    border: 1px solid gray;
  }
  .history-button{
    top: -15px;
    position: absolute;
    left: 95px;
  }
`;
