import { UploadFile } from 'antd/lib/upload/interface';
import { ServiceType } from '../enums/ServiceType';
import { SwaggerSource } from '../enums/SwaggerSource';
import { LoadBalancingPolicies } from '../enums/LoadBalancingPolicies';

export class SwaggerService {
  id: string | null;
  groupName: string;
  serviceName: string;
  type: ServiceType.Swagger;
  swaggerSource: SwaggerSource;
  swaggerFile: UploadFile<any>[];
  url: string;
  additionalUrls: string[];
  balancingPolicy: LoadBalancingPolicies;
  swaggerPath: string;

  constructor(
    id: string | null = null,
    groupName: string = '',
    serviceName: string = '',
    swaggerSource: SwaggerSource = SwaggerSource.Local,
    swaggerFile: UploadFile<any>[] = [],
    url: string = '',
    additionalUrls: string[] = [],
    balancingPolicy: LoadBalancingPolicies = LoadBalancingPolicies.FirstAlphabetical,
    swaggerPath: string = '',
  ) {
    this.id = id;
    this.groupName = groupName;
    this.serviceName = serviceName;
    this.type = ServiceType.Swagger;
    this.swaggerSource = swaggerSource;
    this.swaggerFile = swaggerFile;
    this.url = url;
    this.additionalUrls = additionalUrls;
    this.balancingPolicy = balancingPolicy;
    this.swaggerPath = swaggerPath;
  }
}
