import React from 'react';
import Icon from '@plasma/ui.general.icon';
import styled from 'styled-components';
import { Empty } from 'antd';

export const StyledEmpty = styled(Empty)`
  .ant-empty-description {
    color: ${(props) => (props.theme.selectedMode === 'light' ? '#1A1A18' : 'white')};
    font-weight: 500;
    font-size: 17px;
  }
`;
export const StyledIcon = styled(Icon)`
  color: ${(props) => (props.theme.selectedMode === 'light' ? '#1A1A18' : 'white')}!important;
`;

interface Props {
  icon?: string;
  text?: string;
  className?: string;
  visible?: boolean;
}

export default function NoData({ icon, text, className, visible = true }: Props) {
  return (
    <div
      className={className}
      style={{
        width: '100%',
        height: '100%',
        display: visible ? 'flex' : 'none',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <StyledEmpty
        imageStyle={{ height: 40 }}
        description={text ?? 'No Data'}
        image={<StyledIcon size={40} name={icon ?? 'StackedBarChart'} />}
      ></StyledEmpty>
    </div>
  );
}
