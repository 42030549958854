import { makeAutoObservable, toJS, transaction } from 'mobx';
import { Api } from '../models/types/api';
import { debounce } from 'lodash';
import { ProcessorService } from '../models/types/ProcessorService';
import GridLayout from 'react-grid-layout';
import { DownstreamService } from '../models/types/downstreamEndpoint';
import { MappedEndpoint } from '../models/types/mappedEnpoint';
import beautify from 'js-beautify';
import { PipelineElement } from '../models/enums/PipelineElement';
import { State } from '../components/shared/ParameterMapper/ParameterMapper';
import { requestBodyToSourceCode } from '../utils/requestBodyToSourceCode';
import { DownstreamEndpointShort } from '../models/types/downstreamEndpointShort';

export default class ApiStore {
  //======================== General ========================

  // Reset the store
  reset = () => {
    transaction(() => {
      this.initialbasicForm = new Api();
      this.basicForm = new Api();
      this.endpointServiceList = [];
      this.pipeline = [];
      this.mappedEndpointServices = [];
      this.processors = [];
      this.selectedElementId = null;
    });
  };
  //======================== Basic Tab ========================

  initialbasicForm = new Api();
  // Basic form for the basic tab
  basicForm: Api = new Api();

  // Debounced method to update basicForm
  updateBasicForm = debounce((value: Api) => {
    this.basicForm = value;
  }, 300);

  // Method to select an element
  selectElement = (id: string | null) => {
    this.selectedElementId = id;
  };

  //#region ======================== Mapping Tab ========================
  // List of available endpoint services
  endpointServiceList: DownstreamEndpointShort[] = [];

  // Layout array representing the pipeline
  pipeline: GridLayout.Layout[] = [];

  // List of mapped endpoint services in the pipeline
  mappedEndpointServices: MappedEndpoint[] = [];

  // List of mapped processors in the pipeline
  processors: ProcessorService[] = [];

  // Selected element in the pipeline
  selectedElementId: string | null = null;

  // Update the pipeline layout
  updatePipeline = (layout: GridLayout.Layout[]) => {
    this.pipeline = layout.sort((a, b) => {
      var keyA = a.y,
        keyB = b.y;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  };

  // Add new processor to the pipeline
  addProcessor = (index: number, type?: string, id?: string) => {
   const _index = type === 'nextBody'?index:index-1
    let service = this.endpointServiceList.find((x) => x.id === id) as DownstreamService;
    let serviceName = service?.name;
    let processorName = `${type??"Processor"} ${serviceName? `- ${serviceName}`:""}`;
    let sourceCode =
      type === 'pre' && service?.requestBody?.body
        ? `nextBody = ${requestBodyToSourceCode(service.requestBody.body)}\n`
        : undefined;
    if (sourceCode) sourceCode = beautify(sourceCode);
    let newProcessor = new ProcessorService(undefined, processorName, sourceCode);
    this.processors.push(newProcessor);

    let newLayoutElement = { x: 0, y: _index, w: 1, h: 1, i: `${newProcessor.id}` };

    this.pipeline.forEach((x) => {
      if (x.i !== newLayoutElement.i && x.y >= index + 1) x.y = x.y + 1;
    });

    let newLayout = [...this.pipeline];
    newLayout.push(newLayoutElement);
    this.updatePipeline(newLayout);
  };

  // Update a processor
  updateProcessor = (_processor: ProcessorService) => {
    let newArray = [...this.processors];
    let indexToUpdate = newArray.findIndex((x) => x.id === _processor.id);
    newArray[indexToUpdate] = { ..._processor };
    this.processors = newArray;
  };

  // Add DownstreamService to the pipeline
  addEndpointService = (id: string, tempLayout: GridLayout.Layout[]) => {
    let service = this.endpointServiceList.find((x) => x.id === id) as DownstreamService;
    let newArray = [...this.mappedEndpointServices];
    this.updatePipeline(tempLayout);
    if (service)
      newArray.push({
        id: id,
        type: PipelineElement.Endpoint,
        mapping: service.parameters?.map((p) => ({
          query: p.name,
          parameter: null,
        })),
      });
    this.mappedEndpointServices = newArray;
  };

  // Update DownstreamService in the pipeline
  updateMappedService = (id: string, mapping: State[]) => {
    let newArray = [...this.mappedEndpointServices];
    let service = newArray.find((x) => x.id === id);
    if (service) service.mapping = mapping;
    this.mappedEndpointServices = newArray;
  };

  // Delete an element from the pipeline
  deleteService = (id: string) => {
    this.updatePipeline([...this.pipeline.filter((x) => x.i !== id)]);
    if (this.mappedEndpointServices.find((x) => x.id === id))
      this.mappedEndpointServices = [...this.mappedEndpointServices].filter((x) => x.id !== id);
    if (this.processors.find((x) => x.id === id)) this.processors = [...this.processors].filter((x) => x.id !== id);
    this.selectElement(null);
  };

  //#endregion

  constructor() {
    makeAutoObservable(this);
  }
}
