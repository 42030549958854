import React from 'react';
import { Navigate, Route } from '@plasma/ui.application.router';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/

const ServiceSwaggerDocumentation = React.lazy(() => import('.'));

export const ServiceSwaggerRoutes = [
  new Route({
    path: '/services/swagger',

    children: [
      new Route({
        path: '',
        element: () => <Navigate to="/services" replace />,
      }),
      new Route({
        path: '/services/swagger/:id',
        element: () => <ServiceSwaggerDocumentation type="service" />,
      }),
    ],
  }),
];
