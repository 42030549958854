import React from 'react';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { ConfirmationProps } from './Confirmation';
import { ListItem } from '@plasma/ui.display.list';
import { useNavigate } from '@plasma/ui.application.router';

function useConfirmationHook(props: ConfirmationProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const doneHandler = () => {
    navigate('/services', { replace: true });
  };

  const list: ListItem<{ title: string }>[] = Array.from(new Set(props.selectedServices.map((x) => x.tag))).map(
    (x) => ({
      item: { title: x },
      subRows: props.selectedServices.filter((y) => y.tag === x).map((z) => ({ item: { title: z.name } })),
    }),
  );

  return {
    t,
    list,
    doneHandler,
  };
}

export default useConfirmationHook;
