export function urlChecker(url: string) {
  try {
    const urlObject = new URL(url);

    const protocol = urlObject.protocol;
    const hostname = urlObject.hostname;
    const port = urlObject.port;
    const path = urlObject.pathname;
    const searchParams = urlObject.searchParams;
    if (protocol.length > 0 && hostname.length > 0) return true;
    return false
  } catch (error) {
    return false;
  }
}
