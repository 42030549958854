import React from 'react';
import { useTranslation } from '@plasma/ui.utils.i18n';
import { ServiceCardProps } from './ServiceCard';
import { OverflowMenuItem } from '@plasma/ui.navigation.overflow-menu';

function useServiceCardHook(props: ServiceCardProps) {
  const clickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    props.onSelectElement(props.elementId!);
  };

  const mappedElement = props.getMappedElement(props.elementId!)
  const element = props.getRootElement(props.elementId!);
  const selected = props.elementId === props.selectedElementId;
  const menuItems: OverflowMenuItem[] = [
    {
      label: 'Add Preprocessor',
      key: 'pre',
      icon: 'add',
      onClick: (key: string) => props.onAddProcessor(props.index! - 1, key, props.elementId!),
    },
    {
      label: 'Add Postprocessor',
      key: 'post',
      icon: 'add',
      onClick: (key: string) => props.onAddProcessor(props.index! + 1, key, props.elementId!),
    },
  ];

  const isEnpointService = element && 'path' in element;

  return {
    menuItems,
    isEnpointService,
    selected,
    clickHandler,
    element,
    mappedElement,
  };
}

export default useServiceCardHook;
