import Icon from '@plasma/ui.general.icon';
import React from 'react';
import styled from 'styled-components';

export interface ErrorIndicatorProps {
  className?: string;
  style?: React.CSSProperties;
  message?: string;
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  z-index: 10;
  .plasma-icon {
    color: red;
  }
`;

export default function ErrorIndicator(props: ErrorIndicatorProps) {
  return (
    <Container className={props.className} style={props.style}>
      <Icon size={40} name="warning_amber" />
      <h4>{props.message}</h4>
    </Container>
  );
}
