import * as esprima from 'esprima';

function validate(sourceCode: string) {
  try {
    const node: esprima.Program = esprima.parseScript(sourceCode, { range: true });
    return true;
  } catch {
    return false;
  }
}

function extractContextVariableNames(sourceCode: string) {
  if (sourceCode) {
    const node: esprima.Program = esprima.parseScript(sourceCode, { range: true });
    const context: string[] = [];
    node.body.forEach((x: any) => {
      if (x.expression?.left?.object?.name === 'context') {
        context.push(x['expression']['left']['property']['name']);
      }
    });
    return context;
  } else return [];
}

function extractVariableNames(sourceCode: string) {
  if (sourceCode) {
    const node: esprima.Program = esprima.parseScript(sourceCode, { range: true });
    //@ts-ignore
    return node.body.map((x) => x['expression']['left']['name']);
  } else return [];
}

export const CodeParser = { validate, extractContextVariableNames, extractVariableNames };
