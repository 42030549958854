import { ApiParameterArea } from '../enums/ApiParameterArea';
import { ParameterTypes } from '../enums/ParameterTypes';

export class endpointParameter {
  name: string;
  in: ApiParameterArea | null;
  description: string;
  required: boolean;
  isArray: boolean;
  enum: string[];
  type: ParameterTypes | null;

  constructor(
    name: string,
    _in: ApiParameterArea | null,
    description: string,
    required: boolean,
    isArray: boolean,
    _enum: string[],
    type: ParameterTypes | null,
  ) {
    this.name = name;
    this.in = _in;
    this.description = description;
    this.required = required;
    this.isArray = isArray;
    this.enum = _enum;
    this.type = type;
  }
}
