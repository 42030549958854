import React from 'react';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useApiMappingHook from './api-mapping.hook';
import * as styled from './api-mapping.styles';

import { observer } from 'mobx-react-lite';
import ServiceCard from '../ServiceCard';

import ScrollableArea from '@plasma/ui.layout.scrollable-area';
import ProcessorForm from '../ProcessorForm';
import { ProcessorService } from '../../../../models/types/ProcessorService';
import { DownstreamService } from '../../../../models/types/downstreamEndpoint';
import { MappedEndpoint } from '../../../../models/types/mappedEnpoint';
import ServiceForm from '../ServiceForm';
import ServiceList from '../ServiceList';
import MappingArea from '../MappingArea';
import ApiStore from '../../../../stores/ApiStore';
import NoData from '../../../core/NoData/NoData';

export interface ApiMappingProps {
  className?: string;
  style?: React.CSSProperties;
  store: ApiStore;
}

type Props = ApiMappingProps & ThemeOverride;

function ApiMapping(props: Props) {
  const { className, style, size, themeMode } = props;

  const logic = useApiMappingHook(props);
  return (
    <ThemeProvider selectedSize={size} selectedMode={themeMode}>
      <styled.ApiMapping className={`api-mapping ${className ?? ''}`.trim()} style={style}>
        <div className="service-list-container">
          <ServiceList
            onDrag={logic.onDragItem}
            services={props.store.endpointServiceList}
            mappedServices={props.store.mappedEndpointServices}
            onSelectElement={props.store.selectElement}
            selectedElementId={props.store.selectedElementId}
          />
        </div>
        <div className="mapping-container">
          <MappingArea
            draggedItem={logic.draggedItem}
            onAddEndpointService={props.store.addEndpointService}
            onAddProcessor={props.store.addProcessor}
            mappingLayout={props.store.pipeline}
            onUpdateMapping={props.store.updatePipeline}
            onBackgroundClick={() => {
              props.store.selectElement(null);
            }}
            cardElement={<ServiceCard
              onSelectElement={props.store.selectElement}
              onAddProcessor={props.store.addProcessor}
              onDeleteElement={props.store.deleteService}
              selectedElementId={props.store.selectedElementId}
              getRootElement={logic.getRootElement}
              getMappedElement={logic.getMappedElement}
            />}
          />
        </div>
        <div className="setting-container">
          {!(props.store.selectedElementId && (logic.selectedElement || props.store.selectedElementId ==="{L}Processor" )) ? (
            <NoData text="No element selected" />
          ) : (
            <ScrollableArea className="config-container">
              {!props.store.endpointServiceList.some(x=>x.id===props.store.selectedElementId?.replace("{L}","")!)  ? (
                <ProcessorForm
                isListElement={props.store.selectedElementId.includes("{L}Processor")}
                  key={props.store.selectedElementId}
                  processor={logic.selectedElement as ProcessorService}
                  updateProcessor={props.store.updateProcessor}
                />
              ) : (
                <ServiceForm
                  key={props.store.selectedElementId}
                  endpoint={logic.selectedElement as DownstreamService}
                  mappedEndpoint={logic.selectedMappedEndpoint as MappedEndpoint}
                  context={logic.selectedContext}
                  isListElement={props.store.selectedElementId.includes("{L}")}
                  updateMappedService={props.store.updateMappedService}
                />
              )}
            </ScrollableArea>
          )}
        </div>
      </styled.ApiMapping>
    </ThemeProvider>
  );
}

export default observer(ApiMapping);
